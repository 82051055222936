import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import P2 from './P2';

interface TextWithIconPillProps {
  text: string;
  icon: ReactElement;
  onClick?: () => void;
  className?: string;
}

const TextWithIconPill: React.FC<TextWithIconPillProps> = ({
  text,
  icon,
  className,
  onClick,
}) => {
  return (
    <Container className={className} onClick={onClick}>
      {icon}
      <Label>{text}</Label>
    </Container>
  );
};

export default TextWithIconPill;

const Container = styled(CenteredHorizontalFlex)`
  width: 164px;
  height: 40px;
  border-radius: 100px;
  background-color: ${Colors.P100};
  gap: 6px;
  padding-left: 12px;

  &:hover {
    background-color: ${Colors.P200};
  }
`;

const Label = styled(P2)`
  color: ${Colors.WHITE};
`;
