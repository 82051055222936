import React, { useEffect, useState } from 'react';
import ErrorModal from './ErrorModal';

interface BeforeUnloadUnsavedDataModalProps {
  shouldWarnUser: () => boolean;
  optimisticUpdate: () => Promise<void>;
}

const BeforeUnloadUnsavedDataModal: React.FC<
  BeforeUnloadUnsavedDataModalProps
> = ({ shouldWarnUser, optimisticUpdate }) => {
  const [showChangesSavedOnExit, setShowChangesSavedOnExit] = useState<{
    show: boolean;
    title: string;
    message: string;
  }>({
    show: false,
    title: '',
    message: '',
  });

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (shouldWarnUser()) {
        optimisticUpdate();
        // .then(() => {
        //   setShowChangesSavedOnExit({
        //     show: true,
        //     title: 'Changes saved successfully',
        //     message: 'You can now safely continue',
        //   });
        // })
        // .catch((e) => {
        //   logger.error('handleBeforeUnload - syncChanges - error: ', e);
        //   setShowChangesSavedOnExit({
        //     show: true,
        //     title: 'Changes not saved',
        //     message:
        //       'There was an error saving your changes, please try again or contact admin',
        //   });
        // });
        event.preventDefault();
        // Most browsers will show a default confirmation dialog
        event.returnValue = ''; // Required for Chrome and Firefox
      }
    };

    window.onbeforeunload = handleBeforeUnload;
    // window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.onbeforeunload = null;
      // window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldWarnUser, optimisticUpdate]);

  return (
    <ErrorModal
      show={showChangesSavedOnExit.show}
      onClose={() =>
        setShowChangesSavedOnExit({ show: false, title: '', message: '' })
      }
      title={showChangesSavedOnExit.title}
      subTitle={showChangesSavedOnExit.message}
    />
  );
};

export default BeforeUnloadUnsavedDataModal;
