export const GENERAL_ERROR_MESSAGE =
  'We encountered an issue, Please try again or contact admin';

export const generalErrorMessages = {
  GET_PROJECTS: (
    <div>
      We're having trouble fetching your projects. Please check your internet
      connection and try again, or contact our{' '}
      <a href='mailto:support@blix.ai'>support team</a> if the problem persists.
    </div>
  ),
  CREATE_PROJECT: (
    <div>
      We encountered an issue while creating your project. Please ensure all
      fields are correctly filled and try again. If this issue continues, please
      contact our <a href='mailto:support@blix.ai'>support team</a>.
    </div>
  ),
};

export const unsupportedOldProjectStateErrorMessages = {
  JUST_STARTED_OR_FAILED: (
    <div>
      We have updated Blix with a new flow and this project page is no longer
      available. To continue please create a new project or contact our support
      at <a href='mailto:info@blix.ai'>info@blix.ai</a>.
    </div>
  ),
  PROCESSING: <div>Coding in progress, please try again in a few minutes</div>,
};

export const blockingSyncErrorMessage =
  'It looks like we encountered an issue syncing your data. Please try again. If the problem persists, contact our support team for assistance.';

export const blockingSyncErrorTitle = 'Error Updating Your Data';

export const blockingSyncLoadingMessage = 'Syncing your data, please wait...';

export const continureAnywayErrorMessage = (
  <div>
    It looks like a network error occurred. Please check your connection and try
    again. If the issue persists, please reach out to us via the in-app chat or
    at <a href='mailto:info@blix.ai'>info@blix.ai</a>.
  </div>
);
