import React from 'react';
import styled from 'styled-components';
import LoaderIcon from '../assets/loader_icon.svg';
import { Colors } from '../theme/Colors';
import CenteredHorizontalFlex from './containers/CenteredHorizontalFlex';
import P2 from './newTextComponents/P2';

interface ToggleInputWithLabelProps {
  label: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  checked?: boolean;
  className?: string;
  loading?: boolean;
  icon?: React.ReactNode;
}

const ToggleInputWithLabel: React.FC<ToggleInputWithLabelProps> = ({
  label,
  onChange,
  checked,
  className,
  loading,
  icon,
}) => {
  return (
    <Container className={className}>
      <ContentContainer>
        {icon}
        <StyledLabel>{label}</StyledLabel>
      </ContentContainer>
      {loading ? (
        <Loader src={LoaderIcon} />
      ) : (
        <ToggleWrapper>
          <ToggleInput
            type='checkbox'
            checked={checked}
            onChange={onChange}
            disabled={loading}
          />
          <ToggleSlider />
        </ToggleWrapper>
      )}
    </Container>
  );
};

export default ToggleInputWithLabel;

const Container = styled(CenteredHorizontalFlex)`
  margin-bottom: 4px;
  justify-content: space-between;
  gap: 8px;
`;

const StyledLabel = styled(P2)``;

const ContentContainer = styled(CenteredHorizontalFlex)`
  gap: 10px;
`;

const Loader = styled.img`
  /* flex: 1; */
  width: 20px;
  height: 20px;
  margin: 0 8px;
  animation: rotation 2s linear infinite;
`;

const ToggleWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: ${Colors.P100};
  }

  &:focus + span {
    box-shadow: 0 0 1px ${Colors.P100};
  }

  &:checked + span:before {
    transform: translateX(16px);
  }
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 16px;

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;
