import React from 'react';

interface SettingsSlidersIconProps {
  className?: string;
  color?: string;
}

const SettingsSlidersIcon: React.FC<SettingsSlidersIconProps> = ({
  className,
  color = '#666666',
}) => {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10 6.5C9.17157 6.5 8.5 7.17157 8.5 8C8.5 8.82843 9.17157 9.5 10 9.5C10.8284 9.5 11.5 8.82843 11.5 8C11.5 7.17157 10.8284 6.5 10 6.5ZM4.75 8.75H7.09451C7.42755 10.0439 8.60212 11 10 11C11.3979 11 12.5725 10.0439 12.9055 8.75H19.25C19.6642 8.75 20 8.41421 20 8C20 7.58579 19.6642 7.25 19.25 7.25H12.9055C12.5725 5.95608 11.3979 5 10 5C8.60212 5 7.42755 5.95608 7.09451 7.25H4.75C4.33579 7.25 4 7.58579 4 8C4 8.41421 4.33579 8.75 4.75 8.75ZM4 16C4 15.5858 4.33579 15.25 4.75 15.25H12.0945C12.4275 13.9561 13.6021 13 15 13C16.3979 13 17.5725 13.9561 17.9055 15.25H19.25C19.6642 15.25 20 15.5858 20 16C20 16.4142 19.6642 16.75 19.25 16.75H17.9055C17.5725 18.0439 16.3979 19 15 19C13.6021 19 12.4275 18.0439 12.0945 16.75H4.75C4.33579 16.75 4 16.4142 4 16ZM13.5 16C13.5 15.1716 14.1716 14.5 15 14.5C15.8284 14.5 16.5 15.1716 16.5 16C16.5 16.8284 15.8284 17.5 15 17.5C14.1716 17.5 13.5 16.8284 13.5 16Z'
        fill={color}
      />
    </svg>
  );
};

export default SettingsSlidersIcon;
