import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Colors } from '../theme/Colors';
import InfoIcon from './icons/InfoIcon';
import XIcon from './icons/XIcon';
import P2 from './newTextComponents/P2';

interface SnackbarProps {
  message: string;
  onClose?: () => void;
  isVisible?: boolean;
}

const Snackbar: React.FC<SnackbarProps> = ({
  message,
  onClose,
  isVisible: initialVisibility = false,
}) => {
  const [isVisible, setIsVisible] = useState(initialVisibility);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isVisible && !isFadingOut) {
      timerRef.current = setTimeout(() => {
        setIsFadingOut(true);
      }, 5000);

      return () => {
        if (timerRef.current) clearTimeout(timerRef.current);
      };
    }
  }, [isVisible, isFadingOut]);

  useEffect(() => {
    if (isFadingOut) {
      const animationTimer = setTimeout(() => {
        setIsVisible(false);
        if (onClose) onClose();
      }, 300); // Match this to the animation duration

      return () => clearTimeout(animationTimer);
    }
  }, [isFadingOut, onClose]);

  const handleClose = () => {
    setIsFadingOut(true);
  };

  if (!isVisible) return null;

  return (
    <Container isFadingOut={isFadingOut}>
      <Content>
        <IconContainer>
          <InfoIcon color={Colors.P100} />
        </IconContainer>
        <Message>{message}</Message>
        <CloseButton onClick={handleClose} aria-label='Close notification'>
          <CloseIcon />
        </CloseButton>
      </Content>
    </Container>
  );
};

export default Snackbar;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const Container = styled.div<{ isFadingOut: boolean }>`
  z-index: 1;
  position: fixed;
  transform: translate(-33%, 8px);
  width: 454px;
  height: 64px;
  animation: ${(props) =>
    props.isFadingOut
      ? css`
          ${fadeOut} 0.3s ease-in-out forwards
        `
      : css`
          ${fadeIn} 0.3s ease-in-out
        `};
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  padding: 16px;
  border-radius: 12px;
  background-color: ${Colors.P5};
  border: 1px solid ${Colors.P20};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${Colors.P20};
  flex-shrink: 0;
`;

const Message = styled(P2)`
  color: ${Colors.B100};
  flex-grow: 1;
  margin: 0;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  color: ${Colors.P60};
  padding: 4px;
  border-radius: 4px;
  flex-shrink: 0;
  transition: color 0.2s ease;

  &:hover {
    color: ${Colors.P100};
  }
`;

const CloseIcon = styled(XIcon)`
  width: 18px;
  height: 18px;
`;
