import styled from 'styled-components';
import { Colors } from '../../theme/Colors';

const MenuIcon = styled.img<{ $clicked: boolean }>`
  position: absolute;
  /* padding: 6px; */
  right: 16px;
  top: 16px;
  height: 32px;
  width: 32px;
  z-index: 1;
  cursor: pointer;

  &:hover {
    ${({ $clicked }) =>
      !$clicked &&
      `
    background-color: ${Colors.P5};
  `}
  }

  ${({ $clicked }) =>
    $clicked &&
    `
    background-color: ${Colors.GRAY_40};
    border-radius: 50%;
  `}
`;

export default MenuIcon;
