import React, { CSSProperties, ReactElement } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import LoaderIcon from '../icons/LoaderIcon';
import P2 from '../newTextComponents/P2';

interface SecondaryButtonWithLoaderProps {
  disabled?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  label: string;
  onClick?: VoidFunction;
  style?: CSSProperties;
  textStyle?: CSSProperties;
  className?: string;
  icon?: ReactElement;
  withClearBackground?: boolean;
  withoutBorder?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

const SecondaryButtonWithLoader: React.FC<SecondaryButtonWithLoaderProps> = ({
  onClick,
  disabled = false,
  isLoading = false,
  loadingText,
  label,
  style,
  textStyle,
  className,
  icon,
  type = 'button',
}) => {
  return (
    <Button
      className={className}
      style={style}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
      $isLoading={isLoading}
      disabled={disabled || isLoading}
      type={type}
      aria-label={'submit-button'}
    >
      {!isLoading && icon && <IconContainer>{icon}</IconContainer>}
      {isLoading && (
        <Loader>
          <StyledLoaderIcon color={Colors.B30} />
        </Loader>
      )}
      <TextLabel $isLoading={isLoading} style={textStyle}>
        {isLoading && loadingText ? loadingText : label}
      </TextLabel>
    </Button>
  );
};

export default SecondaryButtonWithLoader;

const Button = styled.button<{
  disabled?: boolean;
  $isLoading?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.P20};
  height: 39px;
  display: flex;
  padding: 8px 24px;
  gap: 10px;

  ${({ disabled }) => disabled && `cursor: default;`}
  ${({ $isLoading }) =>
    $isLoading
      ? `border: 1px solid  ${Colors.B20};
        background-color: ${Colors.B10};
        color: ${Colors.B30};
        `
      : `background-color: ${Colors.WHITE};`}

  border-radius: 8px;

  &:hover {
    background-color: ${Colors.P5};
    border: 1px solid ${Colors.P20};
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }
`;

const TextLabel = styled(P2)<{
  $isLoading?: boolean;
}>`
  color: ${({ $isLoading }) => ($isLoading ? Colors.B30 : Colors.B80)};
`;

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
`;

const StyledLoaderIcon = styled(LoaderIcon)`
  width: 20px;
  height: 20px;
`;

const Loader = styled.div`
  flex: 1;
  width: 20px;
  height: 20px;
  animation: rotation 2s linear infinite;
`;
