import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { fetchHealthStatus } from './api/pipelineApi';
import MessageModal from './components/modal/MessageModal';
import { useFormContext } from './state/useFormContext';
import logger from './utils/logger';

const NewFlow: React.FC = () => {
  const location = useLocation();

  const { error } = useQuery({
    queryKey: ['healthStatus'],
    queryFn: fetchHealthStatus,
    refetchInterval: 300000, // Refetch every 5 minutes
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  if (error) {
    logger.info('health check error: ', error);
  }

  const { showUpdateAppVersionDialog, refreshAppVersion } = useFormContext();

  const isInSplitViewCodebookEditor =
    location.pathname.split('/').slice(-1)[0] === 'split-view-codebook-editor';

  const showDialog = showUpdateAppVersionDialog && !isInSplitViewCodebookEditor;

  return (
    <Container>
      {showDialog && (
        <MessageModal
          withCloseButton={false}
          show={showDialog}
          approveButtonText='OK'
          onApprove={refreshAppVersion}
          title='New Blix Version'
          subTitle='Great news! A new version of Blix is here! Click OK to update now – your work is saved.'
        />
      )}
      <Outlet />
    </Container>
  );
};

export default NewFlow;

const Container = styled.div`
  position: absolute;
  inset-inline-start: 0px;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-block-end: 0px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  /* overflow: scroll; */
`;
