import React from 'react';

interface LoaderIconProps {
  className?: string;
  color?: string;
}

const LoaderIcon: React.FC<LoaderIconProps> = ({
  className,
  color = '#6B57C6',
}) => {
  return (
    <svg
      className={className}
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M15.9997 5.33398C16.552 5.33398 16.9997 5.7817 16.9997 6.33398V8.33398C16.9997 8.88627 16.552 9.33398 15.9997 9.33398C15.4474 9.33398 14.9997 8.88627 14.9997 8.33398V6.33398C14.9997 5.7817 15.4474 5.33398 15.9997 5.33398ZM8.4581 8.45868C8.84874 8.06826 9.4819 8.06844 9.87231 8.45908L12.2283 10.8164C12.6187 11.207 12.6185 11.8402 12.2279 12.2306C11.8373 12.621 11.2041 12.6209 10.8137 12.2302L8.4577 9.87289C8.06729 9.48226 8.06747 8.84909 8.4581 8.45868ZM23.5414 8.45888C23.932 8.8494 23.932 9.48257 23.5414 9.87309L22.0401 11.3744C21.6496 11.7649 21.0164 11.7649 20.6259 11.3744C20.2354 10.9839 20.2354 10.3507 20.6259 9.96021L22.1272 8.45888C22.5178 8.06835 23.1509 8.06835 23.5414 8.45888ZM5.33301 16.0007C5.33301 15.4484 5.78072 15.0007 6.33301 15.0007H9.66634C10.2186 15.0007 10.6663 15.4484 10.6663 16.0007C10.6663 16.5529 10.2186 17.0007 9.66634 17.0007H6.33301C5.78072 17.0007 5.33301 16.5529 5.33301 16.0007ZM22.6663 16.0007C22.6663 15.4484 23.1141 15.0007 23.6663 15.0007H25.6663C26.2186 15.0007 26.6663 15.4484 26.6663 16.0007C26.6663 16.5529 26.2186 17.0007 25.6663 17.0007H23.6663C23.1141 17.0007 22.6663 16.5529 22.6663 16.0007ZM19.7699 19.7709C20.1604 19.3804 20.7936 19.3804 21.1841 19.7709L23.5414 22.1282C23.932 22.5187 23.932 23.1519 23.5414 23.5424C23.1509 23.9329 22.5178 23.9329 22.1272 23.5424L19.7699 21.1851C19.3794 20.7946 19.3794 20.1614 19.7699 19.7709ZM12.2268 19.7722C12.6173 20.1627 12.6173 20.7959 12.2268 21.1864L9.86945 23.5438C9.47892 23.9343 8.84576 23.9343 8.45523 23.5438C8.06471 23.1532 8.06471 22.5201 8.45523 22.1295L10.8126 19.7722C11.2031 19.3817 11.8363 19.3817 12.2268 19.7722ZM15.9997 21.334C16.552 21.334 16.9997 21.7817 16.9997 22.334V25.6673C16.9997 26.2196 16.552 26.6673 15.9997 26.6673C15.4474 26.6673 14.9997 26.2196 14.9997 25.6673V22.334C14.9997 21.7817 15.4474 21.334 15.9997 21.334Z'
        fill={color}
      />
    </svg>
  );
};

export default LoaderIcon;
