import {
  ManualEdit,
  TagChange,
  TagContent,
  Theme,
  UserContentData,
} from '../types';
import { isActiveTagChange } from './theme-utils';

export function extractUntaggedUserContents(
  tagChangesMap: Record<number, TagChange[]>,
  tagContents: TagContent[],
  userContentsMap: Record<number, UserContentData>
) {
  // Create a Set of user content IDs from tagContents for efficient lookup
  const taggedContentsNotRemovedByTagChanges = tagContents.filter(
    (tagContent) =>
      !!userContentsMap[tagContent.user_content_id] &&
      !tagChangesMap[tagContent.user_content_id]?.some(
        (tc) =>
          tc.action === 'remove' &&
          isActiveTagChange(tc) &&
          tc.themeId === tagContent.theme_id
      )
  );

  const taggedContentsNotRemovedByTagChangesIds = new Set(
    taggedContentsNotRemovedByTagChanges.map((tc) => tc.user_content_id)
  );

  const userContentIdsWithAddTagChanges = new Set(
    Object.keys(tagChangesMap)
      .filter((userContentId) =>
        tagChangesMap[Number(userContentId)].some(
          (tagChange) =>
            tagChange.action === 'add' && isActiveTagChange(tagChange)
        )
      )
      .map(Number)
  );

  const untaggedUserContents = Object.values(userContentsMap).filter(
    (uc) =>
      !taggedContentsNotRemovedByTagChangesIds.has(uc.id) &&
      !userContentIdsWithAddTagChanges.has(uc.id)
  );

  return untaggedUserContents;
}

export function computeAddTagContentsFromChanges(
  tagChangesMap: Record<number, TagChange[]>,
  manualEditsMap: Record<number, ManualEdit[]>,
  systemTagContents: TagContent[],
  themes: Theme[],
  userContents: UserContentData[]
): TagContent[] {
  const manuallyAddedTagContents: TagContent[] = [];

  // Create a map of theme id to theme name for quick lookup
  const themeMap = new Map<number, string>();
  themes.forEach((theme) => {
    if (theme.id) {
      themeMap.set(theme.id, theme.name);
    }
  });

  // Process each user content
  userContents.forEach((userContent) => {
    const userContentTagChanges = tagChangesMap[userContent.id] || [];
    const userContentManualEdits = manualEditsMap[userContent.id] || [];
    // Filter only 'add' tag changes that are either pending or syncing
    const addTagChanges = userContentTagChanges.filter(
      (change) =>
        change.action === 'add' &&
        isActiveTagChange(change) &&
        change.themeId !== undefined // Only process changes with theme ID
    );

    // Create tag content entries for each add tag change
    addTagChanges
      .filter(
        // filter out add tag changes that are manually added
        (change) =>
          !userContentManualEdits.some(
            (edit) => edit.themeId === change.themeId && edit.type === 'add'
          )
      )
      .filter((change) => {
        return !systemTagContents.some(
          (tagContent) =>
            tagContent.theme_id === change.themeId &&
            tagContent.user_content_id === userContent.id
        );
      })
      .forEach((change) => {
        if (change.themeId && themeMap.has(change.themeId)) {
          manuallyAddedTagContents.push({
            content: userContent.text,
            meta: '', // You can add metadata if needed
            theme_id: change.themeId,
            theme_name: themeMap.get(change.themeId)!,
            user_content_id: userContent.id,
          });
        }
      });
  });

  return manuallyAddedTagContents;
}
export function computeThemeIdToUserContentsAddedByTagChangesMap(
  tagChangesMap: Record<number, TagChange[]>,
  manualEditsMap: Record<number, ManualEdit[]>,
  systemTagContents: TagContent[],
  themes: Theme[],
  userContents: UserContentData[]
): Map<number, UserContentData[]> {
  const userContentsMap = new Map<number, UserContentData>();
  userContents.forEach((userContent) => {
    userContentsMap.set(userContent.id, userContent);
  });
  // Create a map of theme id to theme name for quick lookup
  const themeToAddedContentsMap = new Map<number, UserContentData[]>();
  themes.forEach((theme) => {
    if (theme.id) {
      themeToAddedContentsMap.set(theme.id, []);
    }
  });

  // Process each user content
  userContents.forEach((userContent) => {
    const userContentTagChanges = tagChangesMap[userContent.id] || [];
    const userContentManualEdits = manualEditsMap[userContent.id] || [];
    // Filter only 'add' tag changes that are either pending or syncing
    const addTagChanges = userContentTagChanges.filter(
      (change) =>
        change.action === 'add' &&
        isActiveTagChange(change) &&
        change.themeId !== undefined // Only process changes with theme ID
    );

    // TODO: we might need to reverse our exclusion pattern, and instead of
    // We're turning out tag changes that were handled manually or by the system.
    // We should do the opposite, Hence, filter out system tags that were handled manually and filter out manual tags that were handled by tag changes.
    // Like we did in other client-side sections.
    addTagChanges
      .filter(
        // filter out add tag changes that are manually added
        (change) =>
          !userContentManualEdits.some(
            (edit) => edit.themeId === change.themeId && edit.type === 'add'
          )
      )
      .filter((change) => {
        // filter out add tag changes that are added by the system
        return !systemTagContents.some(
          (tagContent) =>
            tagContent.theme_id === change.themeId &&
            tagContent.user_content_id === userContent.id
        );
      })
      .forEach((change) => {
        if (change.themeId && themeToAddedContentsMap.has(change.themeId)) {
          themeToAddedContentsMap
            .get(change.themeId)!
            .push(userContentsMap.get(userContent.id)!);
        }
      });
  });

  return themeToAddedContentsMap;
}

export const updateTagChangesStatus = (
  tagChangesMap: Record<number, TagChange[]>,
  fromStatus: 'pending' | 'syncing',
  toStatus: 'pending' | 'syncing' | 'synced',
  // if provided, only update the status of the tag changes for the tag changes in the filterTagChanges array
  filterTagChanges?: TagChange[]
) => {
  const filterIds = filterTagChanges?.map((tagChange) => tagChange.id);
  const updatedTagChangesMap: Record<number, TagChange[]> = Object.fromEntries(
    Object.entries(tagChangesMap).map(([userContentId, tagChanges]) => [
      userContentId,
      tagChanges.map((tagChange) =>
        tagChange.status === fromStatus &&
        (!filterIds?.length || filterIds?.includes(tagChange.id))
          ? { ...tagChange, status: toStatus }
          : tagChange
      ),
    ])
  );

  return updatedTagChangesMap;
};
