import { Colors } from '../../theme/Colors';
import { FontFamily } from '../../theme/FontFamily';
export const elipsysTextOverflow = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const p1TextStyle = `
  font-family: ${FontFamily.ROBOTO};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.B100};
`;
