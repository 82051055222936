import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useNavigationType, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { downloadDashboardFile, reWriteProjectXlsxFile } from '../api/filesApi';
import {
  UpdateJobVersionRequestBody,
  cancelReWriteResultsFileApi,
  fetchProjectApi,
  fetchThemesApi,
  getFirstJobVersionForProjectApi,
  getJobVersionsForJobApi,
  getNonEmptyUserContentCount,
  getTagsApi,
  reAnalyzeQuestionApi,
  updateJobVersionApi,
} from '../api/pipelineApi';
import CursorIcon from '../assets/cursor.png';
import DownloadIcon from '../assets/download.svg';

import PortraitDrawingIcon from '../assets/drawing.svg';
import NotebookWithPencilIcon from '../assets/notebook_with_pencil.svg';
import SparklesIcon from '../assets/sparkles.svg';
import ThreeDotsMenuIcon from '../assets/three_dots_menu.svg';
import TranslateIcon from '../assets/translate.svg';
import PageHeader from '../components/PageHeader';
import Separator from '../components/Seperator';
import Snackbar from '../components/Snackbar';
import ToggleInputWithLabel from '../components/ToggleInputWithLabel';
import RestoreVersionMenu from '../components/business-flows/restore-version/RestoreVersionMenu';
import useRestoreVersion from '../components/business-flows/restore-version/useRestoreVersion';
import BackButtonWithActive from '../components/buttons/BackButtonWithActive';
import SecondaryButtonWithLoader from '../components/buttons/SecondaryButtonWithLoader';
import StyledBackIcon from '../components/buttons/StyledBackIcon';
import CenteredVerticalFlex from '../components/containers/CenteredVerticalFlex';
import HorizontalFlex from '../components/containers/HorizontalFlex';
import VerticalFlex from '../components/containers/VerticalFlex';
import CohortsFilter from '../components/dashboard/CohortsFilter';
import Header from '../components/dashboard/DashboardHeader';
import Section from '../components/dashboard/Section';
import ChevronRightIcon from '../components/icons/ChevronRightIcon';
import SettingsSlidersIcon from '../components/icons/SettingsSlidersIcon';
import Menu from '../components/menu/Menu';
import MenuIcon from '../components/menu/MenuIcon';
import MenuItemIcon from '../components/menu/MenuItemIcon';
import MenuButton from '../components/menu/MenuItemTextButtonWithIcon';
import BeforeUnloadUnsavedDataModal from '../components/modal/BeforeUnloadUnsavedDataModal';
import ErrorModal from '../components/modal/ErrorModal';
import MessageModal from '../components/modal/MessageModal';
import MediumTextWithIcon from '../components/newTextComponents/MediumTextWithIcon';
import P2 from '../components/newTextComponents/P2';
import { elipsysTextOverflow } from '../components/newTextComponents/SharedTextStyles';
import TextWithIconPill from '../components/newTextComponents/TextWithIconPill';
import AutoFocusedInputModal from '../components/project/AutoFocusedInputModal';
import LoadingModal from '../components/tagResults/LoadingModal';
import TagResultsWithScrollToEnd from '../components/tagResults/TagResultsWithScrollToEnd';
import useDashboardData from '../hooks/useDashboardData';
import useFilters from '../hooks/useFilters';
import useIsRestoreVersionEnabled from '../hooks/useIsRestoreVersionEnabled';
import useManualTagManager from '../hooks/useManualTagManager';
import useOutsideClickHandler from '../hooks/useOutsideClickHandler';
import usePeriodicCheck from '../hooks/usePeriodicCheck';
import useTranslation from '../hooks/useTranslation';
import { Colors } from '../theme/Colors';
import { JobVersionData } from '../types';
import {
  GENERAL_ERROR_MESSAGE,
  blockingSyncLoadingMessage,
  continureAnywayErrorMessage,
} from '../utils/constants';
import { downloadThemesFileUtil } from '../utils/csv-utils';
import logger from '../utils/logger';

interface ReviewProjectPageProps {}

const ReviewProjectPage: React.FC<ReviewProjectPageProps> = () => {
  const { projectId, jobId, jobVersionId } = useParams();
  const navigationType = useNavigationType();

  const navigate = useNavigate();

  if (!projectId || !jobVersionId || !jobId) {
    throw new Error('projectId and jobVersionId are required');
  }

  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showUploadLogoModal, setShowUploadLogoModal] = useState(false);
  const [dashboardTitleLocalState, setDashboardTitleLocalState] = useState('');
  const [logoLocalState, setLogoLocalState] = useState('');
  const [showGetThemesErrorModal, setShowGetThemesErrorModal] = useState(false);
  const [
    deprecatedDownloadDashboardError,
    setDeprecatedDownloadDashboardError,
  ] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const queryClient = useQueryClient();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const { data: projectData, isPending: projectLoading } = useQuery<any, Error>(
    {
      queryKey: ['project', projectId],
      queryFn: () => fetchProjectApi(projectId!),
      retry: 3,
      refetchOnWindowFocus: false,
    }
  );

  const { data: nonEmptyUserContentCountData } = useQuery<any, Error>({
    queryKey: ['nonEmptyUserContentCount', jobId],
    queryFn: () => getNonEmptyUserContentCount(jobId!),
    retry: 5,
    refetchOnWindowFocus: false,
  });

  const {
    mutate: updateJobVersionMutate,
    isPending: updateJobVersionIsLoading,
    error: updateJobVersionError,
    reset: updateJobVersionReset,
  } = useMutation<any, Error, UpdateJobVersionRequestBody>({
    mutationFn: (data) => updateJobVersionApi(jobVersionId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['dashboard', jobVersionId] });
    },
  });

  // depracated - serves old projects with a single job version per project
  const { data: jobVersionData } = useQuery<any, Error>({
    queryKey: ['jobConfigurationForProject', projectId],
    queryFn: () => getFirstJobVersionForProjectApi(projectId!),
    refetchOnWindowFocus: false,
  });

  const { data: jobVersionsData } = useQuery<JobVersionData[], Error>({
    queryKey: ['versionsDataForJob', jobId, jobVersionId],
    queryFn: () => getJobVersionsForJobApi(jobId!),
    refetchOnWindowFocus: true,
  });

  const {
    restoreVersion,
    restoreVersionIsLoading,
    restoreVersionError,
    restoreVersionReset,
  } = useRestoreVersion(projectId, jobId, jobVersionId, setShowMenu);

  useEffect(() => {
    if (
      navigationType === 'POP' &&
      jobVersionsData &&
      !restoreVersionIsLoading
    ) {
      // User navigated via back or forward button
      const version = jobVersionsData?.find(
        (v) => v.id === parseInt(jobVersionId)
      );
      if (version && !version.isActive) {
        restoreVersion(version);
      }
    }
  }, [
    jobVersionId,
    restoreVersion,
    jobVersionsData,
    navigationType,
    restoreVersionIsLoading,
  ]);

  const getXlsxFileName = () => {
    const fileName = `${projectData.name || 'Survey Analysis'}_coded_data.xlsx`;

    return fileName;
  };

  const isRestoreVersionEnabled = useIsRestoreVersionEnabled(
    jobId,
    jobVersionId
  );

  const {
    mutate: deprecatedDownloadFileMutate,
    isPending: deprecatedDownloadFileIsLoading,
    error: deprecatedDownloadFileError,
    reset: deprecatedDownloadFileReset,
  } = useMutation<any, Error>({
    mutationFn: () =>
      downloadDashboardFile(
        jobVersionData.id,
        projectData.output_file_name || jobVersionData.output_file_name,
        getXlsxFileName()
      ),
  });

  const {
    mutateAsync: reAnalyzeQuestionMutateAsync,
    isPending: reAnalyzeQuestionIsLoading,
    error: reAnalyzeQuestionError,
    reset: reAnalyzeQuestionReset,
  } = useMutation<any, Error, string>({
    mutationFn: (jobId) => reAnalyzeQuestionApi(projectId, jobId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['jobDisplay', projectId, jobId],
      });
    },
  });

  const {
    mutate: reWriteXlsxMutate,
    isPending: reWriteXlsxIsLoading,
    error: reWriteXlsxError,
    reset: reWriteXlsxReset,
  } = useMutation<any, Error>({
    mutationFn: () => {
      // Abort previous request if exists
      abortControllerRef.current?.abort();

      const controller = new AbortController();
      abortControllerRef.current = controller;

      return reWriteProjectXlsxFile(
        jobVersionId!,
        getXlsxFileName(),
        controller.signal
      );
    },
  });

  const cancelReWrite = useCallback(async () => {
    abortControllerRef.current?.abort();
    await cancelReWriteResultsFileApi(jobVersionId);
  }, [jobVersionId]);

  const jobVersionIdForThemes = projectData?.old_status
    ? jobVersionData?.id
    : jobVersionId;

  const abortControllerRef = useRef<AbortController | null>(null);

  const {
    data: fetchThemesData,
    error: fetchThemesError,
    refetch: fetchThemesRefetch,
    isPending: fetchThemesLoading,
  } = useQuery<any, Error>({
    queryKey: ['themes', jobVersionIdForThemes],
    queryFn: () => fetchThemesApi(jobVersionIdForThemes!),
    retry: 3,
    refetchOnWindowFocus: false,
    // enabled: false,
  });

  const {
    data: tagsData,
    error: tagsError,
    refetch: tagsRefetch,
    isPending: tagsLoading,
  } = useQuery<any, Error>({
    queryKey: ['tags', jobVersionId],
    queryFn: () => getTagsApi(jobVersionId!),
    refetchOnWindowFocus: false,
  });

  const {
    tagChangesMap,
    userContentUnderEdit,
    syncLoading,
    syncError,
    resetLoadingAndError,
    addTag,
    removeTag,
    setUserContentUnderEdit,
    syncTagChanges,
    syncInProgress,
    manualEditsMap,
    manualEditsLoading,
    resetTagChangesMap,
  } = useManualTagManager(jobVersionId);

  const {
    dashboardData,
    dashboardIsLoading,
    dashboardError,
    showCohorts,
    dashboardThemesData,
    metaDataFilter,
    setMetaDataFilter,
    respondentsCount,
  } = useDashboardData(jobVersionId, tagChangesMap, manualEditsMap);

  const {
    toggleTranslate,
    presentTranslation,
    resetTranslationError,
    translateToggleChecked,
    translationIsLoading,
    translationError,
  } = useTranslation(jobVersionId, jobId);

  const layoutDirection =
    dashboardData?.codebook_language === 'Hebrew' ? 'RTL' : 'LTR';

  useEffect(() => {
    setDashboardTitleLocalState(dashboardData?.dashboard_title || '');
  }, [dashboardData]);

  useEffect(() => {
    if (fetchThemesError) {
      logger.error('fetchThemesError: ', fetchThemesError);
      setShowGetThemesErrorModal(true);
    }
  }, [fetchThemesError]);

  const hasTagChangesOrCodebookDirty = useCallback(() => {
    logger.info('hasTagChangesOrCodebookDirty - started');

    const hasActiveTagChanges = Object.values(tagChangesMap).some(
      (value) =>
        value.filter((x) => ['pending', 'syncing'].includes(x.status)).length >
        0
    );

    logger.info(
      'hasTagChangesOrCodebookDirty - hasActiveTagChanges: ',
      hasActiveTagChanges
    );

    return hasActiveTagChanges;
  }, [tagChangesMap]);

  const [isBlockingSyncLoading, setIsBlockingSyncLoading] = useState(false);
  const [showUnsavedDataWarningMetadata, setShowUnsavedDataWarningMetadata] =
    useState<{
      showModal: boolean;
      onApprove?: () => Promise<void>;
      onClose?: () => Promise<void>;
    }>({
      showModal: false,
      onApprove: undefined,
      onClose: undefined,
    });

  const [showFileExportWillBeLostModal, setShowFileExportWillBeLostModal] =
    useState<{
      showModal: boolean;
      onApprove?: () => Promise<void>;
      onClose?: () => Promise<void>;
    }>({
      showModal: false,
      onApprove: undefined,
      onClose: undefined,
    });

  const syncChanges = useCallback(
    async ({
      blocking = false,
      withLoader = true,
    }: { blocking?: boolean; withLoader?: boolean } = {}) => {
      if (syncInProgress) {
        // don't throw on blocking, we'll try to optimistically update and let the api throw if it fails
        logger.error('syncChanges is called while syncInProgress');
      }

      try {
        if (blocking && withLoader) {
          setIsBlockingSyncLoading(true);
        }

        await syncTagChanges(jobVersionId!);

        if (blocking && withLoader) {
          setIsBlockingSyncLoading(false);
        }
        return;
      } catch (error) {
        logger.error('Sync failed:', error);

        if (withLoader) {
          setIsBlockingSyncLoading(false);
        }

        if (blocking) {
          setIsBlockingSyncLoading(false);
          throw error;
        }

        return;
      }
    },
    [jobVersionId, syncTagChanges, syncInProgress, setIsBlockingSyncLoading]
  );

  usePeriodicCheck({
    idleTime: 5, // Consider idle after 10 seconds
    predicate: hasTagChangesOrCodebookDirty,
    onAction: syncChanges,
  });

  const { filteredData, filters, setFilters } = useFilters(
    tagsData,
    tagChangesMap,
    manualEditsMap,
    fetchThemesData ?? []
  );

  const onRenameProjectClick = () => {
    setShowMenu(false);
    updateJobVersionMutate({ dashboard_title: dashboardTitleLocalState });
    setShowRenameModal(false);
  };

  const onUploadLogoClick = () => {
    setShowMenu(false);
    updateJobVersionMutate({
      dashboard_logo_url: logoLocalState,
    });
    setShowUploadLogoModal(false);
  };

  const menuIconRef = useRef<HTMLImageElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  useOutsideClickHandler([menuIconRef, menuRef], () => {
    setShowMenu(false);
  });

  const hasTagChanges = useCallback(() => {
    const hasActiveTagChanges = Object.values(tagChangesMap).some(
      (value) =>
        value.filter((x) => ['pending', 'syncing'].includes(x.status)).length >
        0
    );

    logger.info('hasTagChanges - hasActiveTagChanges: ', hasActiveTagChanges);

    return hasActiveTagChanges;
  }, [tagChangesMap]);

  const onDownloadXlsxFileClick = async () => {
    if (reWriteXlsxIsLoading) {
      logger.error(
        "onDownloadXlsxFileClick shouldm't get called while downloading"
      );
      return;
    }

    const action = () => {
      if (projectData.old_status) {
        if (
          !jobVersionData?.id ||
          (!projectData.output_file_name && !jobVersionData.output_file_name)
        ) {
          logger.error('jobVersionData is missing');
          setDeprecatedDownloadDashboardError(
            `The dashboard file is missing for project id ${projectId}, please contact admin for help`
          );
          return;
        }

        deprecatedDownloadFileMutate();
      } else {
        setShowSnackbar(true);
        reWriteXlsxMutate();
      }
      setShowMenu(false);
    };

    if (!hasTagChanges()) {
      logger.info('onDownloadXlsxFileClick - no tag changes, skipping sync');
      action();
      return;
    }

    try {
      await syncChanges({ blocking: true, withLoader: true });
      logger.info('onDownloadXlsxFileClick - syncChanges - success');
      action();
    } catch (error) {
      logger.error('onDownloadXlsxFileClick - syncChanges - error: ', error);
      setShowUnsavedDataWarningMetadata({
        showModal: true,
        onApprove: async () => {
          action();
        },
      });
    }
  };

  const blockingSyncChanges = async (onContinueAnywayOnError?: () => void) => {
    if (hasTagChanges()) {
      logger.info('blockingSyncChanges - has tag changes, sync');
      try {
        await syncChanges({ blocking: true });
      } catch (error) {
        logger.error('blockingSyncChanges - syncChanges - error: ', error);
        setShowUnsavedDataWarningMetadata({
          showModal: true,
          onApprove: async () => {
            onContinueAnywayOnError?.();
          },
        });

        // rethrow the error so it bubbles up to the caller, so we break the flow
        throw error;
      }
    }

    if (reWriteXlsxIsLoading) {
      setShowFileExportWillBeLostModal({
        showModal: true,
        onApprove: async () => {
          onContinueAnywayOnError?.();
        },
      });

      throw new Error('Download is in progress');
    }
  };

  const onDownloadCodebookClick = async () => {
    setShowMenu(false);
    if (hasTagChanges()) {
      logger.info('onDownloadCodebookClick - has tag changes, syncing');
      syncChanges();
    }
    const fetchThemesData = await fetchThemesRefetch();
    const fileName = `${
      dashboardData?.dashboard_title || 'Survey Analysis'
    }_codebook`;
    if (fetchThemesData.data) {
      downloadThemesFileUtil(fetchThemesData.data, `${fileName}`);
    } else {
      alert('no themes were found, please contact admin');
    }
  };

  const validateTagChangesAndFileExportWithAction = async (
    action: () => void,
    callerName: string
  ) => {
    try {
      if (hasTagChanges()) {
        logger.info(
          `validateTagChangesAndFileExportWithAction - ${callerName} - has tag changes, syncing`
        );
        await syncChanges({ blocking: true });
      }

      if (reWriteXlsxIsLoading) {
        logger.info(
          `validateTagChangesAndFileExportWithAction - ${callerName} - download is in progress, showing file export will be lost modal`
        );
        setShowFileExportWillBeLostModal({
          showModal: true,
          onApprove: async () => {
            action();
          },
        });

        return;
      } else {
        logger.info(
          `validateTagChangesAndFileExportWithAction - ${callerName} - no download in progress, navigating`
        );
        action();
      }
    } catch (error) {
      logger.error(
        `validateTagChangesAndFileExportWithAction - ${callerName} - syncChanges - error: `,
        error
      );
      setShowUnsavedDataWarningMetadata({
        showModal: true,
        onApprove: async () => {
          action();
        },
      });
    }
  };

  const navigateBack = async () => {
    let action;
    if (projectData?.old_status) {
      action = () => navigate(`/projects/`);
    } else {
      action = () => navigate(`/projects/${projectId}`);
    }

    await validateTagChangesAndFileExportWithAction(action, 'navigateBack');
  };

  const onReAnalyzeQuestion = async () => {
    const action = async () => {
      const res = await reAnalyzeQuestionMutateAsync(jobId);
      navigate(
        `/projects/${projectId}/${res.job.id}/${res.jobVersion.id}/split-view-codebook-editor`
      );
    };

    await validateTagChangesAndFileExportWithAction(
      action,
      'onReAnalyzeQuestion'
    );
  };

  if (
    dashboardIsLoading ||
    fetchThemesLoading ||
    projectLoading ||
    restoreVersionIsLoading ||
    !jobVersionsData ||
    !dashboardData ||
    !dashboardThemesData
  )
    return (
      <LoadingModal title='Loading Dashboard...' hidden={!dashboardIsLoading} />
    );
  if (dashboardError)
    return (
      <ErrorModal
        show={!!dashboardError}
        onClose={() => navigate('/projects')}
        title='Dashboard Loading Error'
        subTitle={dashboardError?.message || GENERAL_ERROR_MESSAGE}
      />
    );

  return (
    <TopContainer>
      <Container>
        <PageHeader
          beforeNavigate={blockingSyncChanges}
          beforeLogout={blockingSyncChanges}
        />
        <SecondaryHorizontalContainer>
          <LeftContainer $isDrawerOpen={isDrawerOpen}>
            <DashboardAndControlsContainer>
              <ControlsContainer>
                <BackButton icon={<StyledBackIcon />} onClick={navigateBack} />
                <RightButtonsContainer>
                  <ExportDataContainer>
                    <ExportDataButton
                      label='Export Data'
                      icon={<Img src={DownloadIcon} />}
                      onClick={onDownloadXlsxFileClick}
                      isLoading={reWriteXlsxIsLoading}
                      loadingText='Exporting data'
                      withClearBackground
                    />
                    {showSnackbar && (
                      <Snackbar
                        message='The report is currently being exported and may take a moment. Leaving this page will cancel the export.'
                        onClose={() => setShowSnackbar(false)}
                        isVisible={showSnackbar}
                      />
                    )}
                  </ExportDataContainer>
                  <SettingsMenuIcon
                    ref={menuIconRef}
                    src={ThreeDotsMenuIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowMenu((x) => !x);
                    }}
                    $clicked={showMenu}
                  />
                </RightButtonsContainer>
              </ControlsContainer>
              {showMenu && (
                <Menu $visible={showMenu} ref={menuRef}>
                  <MenuButton
                    text='Change Dashboard Title'
                    icon={<MenuItemIcon src={CursorIcon} />}
                    onClick={() => setShowRenameModal(true)}
                  />
                  <MenuButton
                    text='Change Dashboard Logo'
                    icon={<MenuItemIcon src={PortraitDrawingIcon} />}
                    onClick={() => setShowUploadLogoModal(true)}
                  />
                  <MenuButton
                    text='Download Codebook'
                    icon={<MenuItemIcon src={DownloadIcon} />}
                    onClick={onDownloadCodebookClick}
                  />
                  <MenuButton
                    text='Re Analyze Question'
                    icon={<MenuItemIcon src={NotebookWithPencilIcon} />}
                    onClick={onReAnalyzeQuestion}
                    textStyle={{ fontSize: 14 }}
                  />
                  {isRestoreVersionEnabled && (
                    <RestoreVersionMenu
                      projectId={projectId}
                      jobId={jobId}
                      activeJobVersionId={jobVersionId}
                      setShowMenu={setShowMenu}
                      beforeAction={blockingSyncChanges}
                    />
                  )}
                  <Separator />
                  <TranslateToggle
                    label='Translate Verbatim'
                    onChange={toggleTranslate}
                    checked={translateToggleChecked}
                    loading={translationIsLoading}
                    icon={<MenuItemIcon src={TranslateIcon} />}
                  />
                </Menu>
              )}
              <DashboardContainer>
                <Header
                  title={dashboardData.dashboard_title || 'Survey Analysis'}
                  logoUrl={dashboardData.dashboard_logo_url}
                />
                {showCohorts ? (
                  <CohortsFilter
                    columnDefinitions={dashboardData.column_definitions}
                    dashboardThemesData={dashboardThemesData}
                    setMetaDataFilter={setMetaDataFilter}
                    metaDataFilter={metaDataFilter}
                  />
                ) : null}
                <Section
                  dashboardThemesData={dashboardThemesData}
                  respondentsCount={
                    respondentsCount ?? dashboardData.respondents_count
                  }
                  layoutDirection={layoutDirection}
                  userContentsLayoutDirection={
                    dashboardData.user_contents_layout_direction
                  }
                  shouldTranslate={presentTranslation}
                />
              </DashboardContainer>
            </DashboardAndControlsContainer>
          </LeftContainer>
          {showRenameModal ? (
            <AutoFocusedInputModal
              maxLength={50}
              show={showRenameModal}
              onClose={() => setShowRenameModal(false)}
              value={dashboardTitleLocalState}
              label='Choose New Dashboard Title'
              placeholder='Acme inc.'
              buttonLabel='Rename'
              onChangeInputValue={setDashboardTitleLocalState}
              onClickCTA={onRenameProjectClick}
            />
          ) : null}
          {showUploadLogoModal ? (
            <AutoFocusedInputModal
              show={showUploadLogoModal}
              onClose={() => setShowUploadLogoModal(false)}
              value={logoLocalState}
              label='Upload New Logo Link'
              placeholder='https://picsum.photos/id/237/200/300'
              buttonLabel='OK'
              onChangeInputValue={setLogoLocalState}
              onClickCTA={onUploadLogoClick}
            />
          ) : null}
          <DrawerContainer $isDrawerOpen={isDrawerOpen}>
            <TabsContainer>
              <Tabs>
                <Tab $isActive={true} onClick={() => {}}>
                  <TabContent
                    text='Edit Coding'
                    icon={<StyledSettingsSlidersIcon color={Colors.P100} />}
                    variant='primary'
                  />
                </Tab>
                <AISideKickTab
                  $isActive={false}
                  onClick={() => {}}
                  $withLeftBorder={true}
                >
                  <AISideKickTabContents>
                    <TabIcon src={SparklesIcon} />
                    <AISideKickLabel>AI Sidekick </AISideKickLabel>
                    <SoonLabel>Soon</SoonLabel>
                  </AISideKickTabContents>
                </AISideKickTab>
              </Tabs>
            </TabsContainer>
            <OpenDrawerToggleButton
              $isOpen={isDrawerOpen}
              onClick={() => setIsDrawerOpen(true)}
            >
              <TextWithIconPill
                onClick={() => setIsDrawerOpen(true)}
                text='Edit Coding'
                icon={<SettingsSlidersIcon color={Colors.WHITE} />}
              />
            </OpenDrawerToggleButton>
            <CloseDrawerToggleButton
              $isOpen={isDrawerOpen}
              onClick={() => setIsDrawerOpen(false)}
            >
              <ChevronRightIcon fill={Colors.P100} />
            </CloseDrawerToggleButton>
            <TagResultsContainer>
              {/* <H3
                onClick={() =>
                  syncCodebookAndTagChanges(jobVersionId, fetchThemesData ?? [])
                }
              >
                Sync Changes
              </H3> */}
              <StyledTagResultsWithScrollToEnd
                shouldAddTagModalRelateToViewport={false}
                withCounter={false}
                filters={filters}
                setFilters={setFilters}
                themesDraft={fetchThemesData ?? []}
                results={filteredData}
                nonEmptyUserContentCount={nonEmptyUserContentCountData?.count}
                onScrollToEnd={() => {}}
                isFetchingNextPage={false}
                pageIsLoading={tagsLoading}
                pageIsLoadingText="Hang tight! We're getting your tagged data"
                createTheme={() => 0}
                addSelectedTextToTheme={() => {}}
                codebookLanguage={
                  dashboardData?.codebook_language as 'Hebrew' | 'English'
                }
                isCodebookInFirstVersion={false}
                translationEnabled={presentTranslation}
                tagChangesMap={tagChangesMap}
                userContentUnderEdit={userContentUnderEdit}
                syncLoading={syncLoading && false}
                syncError={syncError}
                resetLoadingAndError={resetLoadingAndError}
                addTag={addTag}
                removeTag={removeTag}
                setUserContentUnderEdit={setUserContentUnderEdit}
                manualEditsMap={manualEditsMap}
                manualEditsLoading={manualEditsLoading}
                withHighlightText={false}
                codebookChangesEnabled={false}
              />
            </TagResultsContainer>
          </DrawerContainer>
        </SecondaryHorizontalContainer>
      </Container>
      <BeforeUnloadUnsavedDataModal
        shouldWarnUser={() => hasTagChanges() || reWriteXlsxIsLoading}
        optimisticUpdate={async () => {
          if (hasTagChanges()) {
            await syncChanges();
          }
        }}
      />
      <MessageModal
        show={showUnsavedDataWarningMetadata.showModal}
        title="Oops! We couldn't save your changes"
        subTitle={continureAnywayErrorMessage}
        approveButtonText='Continue anyway'
        cancelButtonText='Cancel'
        onApprove={async () => {
          await showUnsavedDataWarningMetadata.onApprove?.();
          resetTagChangesMap();
          setShowUnsavedDataWarningMetadata({
            showModal: false,
          });
        }}
        onClose={async () => {
          await showUnsavedDataWarningMetadata.onClose?.();
          await syncChanges({ withLoader: false });
          setShowUnsavedDataWarningMetadata({
            showModal: false,
          });
        }}
      />
      <MessageModal
        show={showFileExportWillBeLostModal.showModal}
        title='Are you sure you want to leave?'
        subTitle='Leaving this page will cancel the data export. Continue?'
        approveButtonText='Yes, Cancel Download'
        cancelButtonText='Go Back'
        onApprove={async () => {
          await cancelReWrite();
          await showFileExportWillBeLostModal.onApprove?.();
          setShowFileExportWillBeLostModal({
            showModal: false,
          });
        }}
        onClose={async () => {
          await showFileExportWillBeLostModal.onClose?.();
          setShowFileExportWillBeLostModal({
            showModal: false,
          });
        }}
      />
      <LoadingModal
        title='Updating dashboard'
        hidden={!updateJobVersionIsLoading}
      />
      <LoadingModal
        hidden={!isBlockingSyncLoading}
        title={blockingSyncLoadingMessage}
      />
      <ErrorModal
        show={!!updateJobVersionError}
        onClose={updateJobVersionReset}
        title='Unexpected Error'
        subTitle={updateJobVersionError?.message ?? GENERAL_ERROR_MESSAGE}
      />
      <LoadingModal
        title='Setting up question for re analyze...'
        hidden={!reAnalyzeQuestionIsLoading}
      />
      <ErrorModal
        show={!!reAnalyzeQuestionError}
        onClose={reAnalyzeQuestionReset}
        title={'Re Analyze Error'}
        subTitle={reAnalyzeQuestionError?.message ?? GENERAL_ERROR_MESSAGE}
      />
      <LoadingModal
        title='Downloading Excel file'
        hidden={!deprecatedDownloadFileIsLoading}
      />
      <ErrorModal
        show={!!deprecatedDownloadFileError}
        onClose={deprecatedDownloadFileReset}
        title='Unexpected Error'
        subTitle={deprecatedDownloadFileError?.message ?? GENERAL_ERROR_MESSAGE}
      />
      <ErrorModal
        show={!!restoreVersionError}
        onClose={restoreVersionReset}
        title='Unexpected Error'
        subTitle={restoreVersionError?.message ?? GENERAL_ERROR_MESSAGE}
      />
      <ErrorModal
        show={!!deprecatedDownloadDashboardError}
        onClose={() => setDeprecatedDownloadDashboardError('')}
        title='Unexpected Error'
        subTitle={deprecatedDownloadDashboardError}
      />
      <ErrorModal
        show={!!showGetThemesErrorModal}
        onClose={() => setShowGetThemesErrorModal(false)}
        title='Unexpected Error'
        subTitle={GENERAL_ERROR_MESSAGE}
      />
      <ErrorModal
        show={!!reWriteXlsxError}
        onClose={reWriteXlsxReset}
        title='Unexpected Error'
        subTitle={reWriteXlsxError?.message ?? GENERAL_ERROR_MESSAGE}
      />
      <ErrorModal
        show={!!tagsError}
        onClose={tagsRefetch}
        title={'Get Tags Error'}
        subTitle={tagsError?.message || GENERAL_ERROR_MESSAGE}
      />
      <ErrorModal
        show={!!translationError}
        onClose={resetTranslationError}
        title={'Translation Error'}
        subTitle={
          'Oops! It looks like we encountered an issue translating your data. Please try again. If the problem persists, contact our support team for assistance.'
        }
      />
    </TopContainer>
  );
};

export default ReviewProjectPage;

const TopContainer = styled(HorizontalFlex)`
  flex: 1;
  gap: 32px;
`;

const Container = styled(VerticalFlex)`
  background-color: ${Colors.WHITE};
  flex: 1;
`;

const SecondaryHorizontalContainer = styled(HorizontalFlex)`
  flex: 1;
  position: relative;
  width: 100vw;
  transition: all 0.3s ease-in-out;
  justify-items: center;
`;

const LeftContainer = styled(HorizontalFlex)<{
  $isDrawerOpen: boolean;
}>`
  position: relative;
  max-height: calc(100vh - 71px);
  width: ${({ $isDrawerOpen }) => ($isDrawerOpen ? '65%' : '100%')};
  transition: width 0.3s ease-in-out;
  justify-content: center;

  overflow-y: auto;
  /* Hide scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Edge */
`;

const DashboardAndControlsContainer = styled(VerticalFlex)`
  position: relative;
  padding: 20px;
  padding-top: 26px;
  max-width: min(75vw, 1100px);
`;

const ControlsContainer = styled(HorizontalFlex)`
  justify-content: space-between;
`;

const RightButtonsContainer = styled(HorizontalFlex)`
  gap: 8px;
`;

const DashboardContainer = styled.div``;

const DrawerContainer = styled(VerticalFlex)<{
  $isDrawerOpen: boolean;
}>`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 35%;

  margin-top: 20px;

  /* Start fully hidden */
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;

  /* Apply transition when drawer opens */
  ${({ $isDrawerOpen }) => $isDrawerOpen && `transform: translateX(0);`}
`;

const BaseDrawerToggleButton = styled.button`
  position: absolute;
  width: 24px;
  height: 64px;
  border: none;
  background-color: ${Colors.P5};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 0 0 4px;
  border: 1px solid ${Colors.GRAY_20};
  border-right: none;
  z-index: 10;
  transition: transform 0.3s ease-in-out;
  border: none;
  &:focus {
    outline: none;
  }
`;

const OpenDrawerToggleButton = styled.div<{
  $isOpen: boolean;
}>`
  position: absolute;
  width: 164px;
  height: 40px;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -100;
  left: -130px;
  top: 23px;
  transition: transform 0.3s cubic-bezier(1, 0.1, 0, 0);
  transform: translateY(-50%)
    ${(props) => (props.$isOpen ? 'translateX(100%)' : 'translateX(0)')};
`;

const CloseDrawerToggleButton = styled(BaseDrawerToggleButton)<{
  $isOpen: boolean;
}>`
  left: -23px;
  top: 398px;
  z-index: 100;
  border: 1px solid ${Colors.P10};
  border-right: none;
  padding: 0;
  border-radius: 8px 0 0 8px;

  transform: translateY(-50%)
    ${(props) => (props.$isOpen ? 'translateX(0)' : 'translateX(110%)')};

  &:hover {
    /* outline: none; */
    border-color: ${Colors.P10};
  }
`;

const TagResultsContainer = styled(VerticalFlex)`
  flex: 1;
  overflow-y: hidden;

  background-color: ${Colors.P5};
  border-left: 1px solid #f0eef9;
  border-right: 1px solid #f0eef9;
`;

const StyledTagResultsWithScrollToEnd = styled(TagResultsWithScrollToEnd)`
  background-color: ${Colors.P5};
  flex: 1;
  overflow-y: hidden;
  /* border: 1px solid ${Colors.P10}; */
`;

const TabsContainer = styled(HorizontalFlex)``;

const Tabs = styled(HorizontalFlex)`
  border-radius: 12px 12px 0 0;
  border-top: 1px solid ${Colors.P10};
  border-left: 1px solid ${Colors.P10};
  border-right: 1px solid ${Colors.P10};
  overflow: hidden;
`;

const Tab = styled(CenteredVerticalFlex)<{
  $isActive: boolean;
  $withLeftBorder?: boolean;
}>`
  ${({ $withLeftBorder }) =>
    $withLeftBorder && `border-left: 1px solid ${Colors.P10};`}
  justify-content: center;
  width: 166px;
  height: 52px;
  background-color: ${Colors.P5};
  cursor: default;
`;

const TabContent = styled(MediumTextWithIcon)`
  font-size: 14px;
`;

const BackButton = styled(BackButtonWithActive)``;

const ExportDataButton = styled(SecondaryButtonWithLoader)`
  gap: 6px;
  border-radius: 8px;
  border: 1px solid ${Colors.P40};
  padding: 6px 12px;
  height: 34px;
  z-index: 1;
  width: 150px; /* when button is loading it will expand to 150px */
  box-shadow: 0px 1px 2px 0px rgba(80, 59, 173, 10%);

  &:hover {
    border: 1px solid ${Colors.P50};
  }
`;

const ExportDataContainer = styled.div`
  position: relative;
`;

const SettingsMenuIcon = styled(MenuIcon)`
  position: unset;
  border-radius: 8px;
  border: 1px solid ${Colors.P40};
  top: 24px;
  box-shadow: 0px 1px 2px 0px rgba(80, 59, 173, 10%);

  &:hover {
    border: 1px solid ${Colors.P50};
  }
`;

const Img = styled.img`
  width: 20px;
  height: 20px;
`;

const TranslateToggle = styled(ToggleInputWithLabel)`
  padding: 16px 24px;
`;

const StyledSettingsSlidersIcon = styled(SettingsSlidersIcon)`
  width: 20px;
  height: 20px;
`;

const TabIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const AISideKickTabContents = styled(HorizontalFlex)`
  align-items: center;
  gap: 4px;
`;

const AISideKickTab = styled(Tab)`
  cursor: no-drop;
  background-color: ${Colors.WHITE};
`;

const AISideKickBaseLabel = styled(P2)`
  font-weight: 400;
  color: ${Colors.B30};
  ${elipsysTextOverflow}
`;

const SoonLabel = styled(AISideKickBaseLabel)`
  padding: 2px 4px;
  background-color: ${Colors.B10};
  border-radius: 4px;
`;

const AISideKickLabel = styled(AISideKickBaseLabel)`
  font-weight: 500;
`;
