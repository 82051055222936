import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import CenteredVerticalFlex from '../containers/CenteredVerticalFlex';
import AddIcon from '../icons/AddIcon';
import P1 from '../newTextComponents/P1';

interface AddThemeItemProps {
  onClick: VoidFunction;
}

const AddThemeItem: React.FC<AddThemeItemProps> = ({ onClick }) => {
  return (
    <TopContainer>
      <ContentContainer onClick={() => onClick()}>
        <StyledAddIcon />
        <Label>Add a New Theme</Label>
      </ContentContainer>
    </TopContainer>
  );
};

const StyledAddIcon = styled(AddIcon)`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid ${Colors.B20};
`;

const Label = styled(P1)`
  line-height: 16px;
  color: ${Colors.B30};
`;

const TopContainer = styled(CenteredVerticalFlex)`
  justify-content: flex-end;
  align-items: stretch;
`;

const ContentContainer = styled(CenteredHorizontalFlex)`
  height: 92px;
  justify-content: center;
  border-radius: 8px;
  border: 1px dashed ${Colors.B20};
  cursor: pointer;
  gap: 10px;

  &:hover {
    background-color: ${Colors.P5};
    border: 1px dashed ${Colors.P100};
  }

  &:hover > ${StyledAddIcon} {
    border: 1px solid ${Colors.P100};
  }

  &:hover > ${Label} {
    color: ${Colors.B100};
  }

  &:active {
    background-color: ${Colors.P10};
    border: 1px dashed ${Colors.P100};
  }

  &:active > ${StyledAddIcon} {
    border: 1px solid ${Colors.P100};
  }

  &:active > ${Label} {
    color: ${Colors.B100};
  }

  &:active > svg {
    background-color: ${Colors.P100};
  }
  &:active > svg path {
    fill: ${Colors.WHITE};
  }

  &:active > svg rect {
    stroke: transparent;
  }
`;

export default AddThemeItem;
