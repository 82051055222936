import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  createOrUpdateJobConfigurationApi,
  fetchProjectApi,
  getJobConfigurationApi,
  getJobVersionApi,
  getNonEmptyUserContentCount,
  multiTagDataApi,
  reshuffleTagSampleOrderApi,
  tagPageApi,
} from '../api/pipelineApi';
import DownloadIcon from '../assets/download.svg';
import MagicWandIcon from '../assets/magic-wand.svg';
import ThreeDotsMenuIcon from '../assets/three_dots_menu.svg';
import TranslateIcon from '../assets/translate.svg';
import PageHeader from '../components/PageHeader';
import Separator from '../components/Seperator';
import ToggleInputWithLabel from '../components/ToggleInputWithLabel';
import RestoreVersionMenu from '../components/business-flows/restore-version/RestoreVersionMenu';
import PrimaryButton from '../components/buttons/PrimaryButton';
import StyledButtonWithActive from '../components/buttons/StyledButtonWithActive';
import CodebookAdvancedSettingsModal, {
  OnCloseFunctionTypeParams,
} from '../components/codebookEditor/CodebookAdvancedSettingsModal';
import CodebookControlBar from '../components/codebookEditor/CodebookControlBar';
import CenteredVerticalFlex from '../components/containers/CenteredVerticalFlex';
import HorizontalFlex from '../components/containers/HorizontalFlex';
import VerticalFlex from '../components/containers/VerticalFlex';
import BackIcon from '../components/icons/BackIcon';
import Menu from '../components/menu/Menu';
import MenuIcon from '../components/menu/MenuIcon';
import MenuButton from '../components/menu/MenuItemTextButtonWithIcon';
import BeforeUnloadUnsavedDataModal from '../components/modal/BeforeUnloadUnsavedDataModal';
import ErrorModal from '../components/modal/ErrorModal';
import MessageModal from '../components/modal/MessageModal';
import H1 from '../components/newTextComponents/H1';
import P1 from '../components/newTextComponents/P1';
import LoadThemesModal from '../components/researchForm/LoadThemesModal';
import LoadingModal from '../components/tagResults/LoadingModal';
import TagResultsWithScrollToEnd from '../components/tagResults/TagResultsWithScrollToEnd';
import ThemesList from '../components/themes/ThemesList';
import useFilters from '../hooks/useFilters';
import useIsRestoreVersionEnabled from '../hooks/useIsRestoreVersionEnabled';
import useManualTagManager from '../hooks/useManualTagManager';
import useOutsideClickHandler from '../hooks/useOutsideClickHandler';
import usePeriodicCheck from '../hooks/usePeriodicCheck';
import useTagsCounter from '../hooks/useTagsCounter';
import useThemesManager from '../hooks/useThemesManager';
import useTranslation from '../hooks/useTranslation';
import { useFormContext } from '../state/useFormContext';
import { Colors } from '../theme/Colors';
import { JobVersionData, JobVersionStatus, Theme } from '../types';
import {
  blockingSyncLoadingMessage,
  continureAnywayErrorMessage,
  GENERAL_ERROR_MESSAGE,
} from '../utils/constants';
import logger from '../utils/logger';
import {
  filterOutEmptyNewThemes,
  hasEmptyNewTheme,
  validateCodebook,
} from '../utils/theme-utils';

interface SplitViewCodebookEditorPageProps {}

const SplitViewCodebookEditorPage: React.FC<
  SplitViewCodebookEditorPageProps
> = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { projectId, jobId, jobVersionId } = useParams();
  if (!projectId || !jobId || !jobVersionId) {
    throw new Error('Missing URL parameters');
  }

  const [showUnsavedDataWarningMetadata, setShowUnsavedDataWarningMetadata] =
    useState<{
      showModal: boolean;
      onApprove?: () => Promise<void>;
      onClose?: () => Promise<void>;
    }>({
      showModal: false,
      onApprove: undefined,
      onClose: undefined,
    });
  const [isBlockingSyncLoading, setIsBlockingSyncLoading] = useState(false);

  const themesListNameInputRefs = useRef<Array<HTMLInputElement | null>>([]);
  // Function to focus on the last added NameInput
  const focusLastAddedNameInput = () => {
    if (themesListNameInputRefs.current.length > 0) {
      const lastInput =
        themesListNameInputRefs.current[
          themesListNameInputRefs.current.length - 1
        ];
      if (lastInput) {
        lastInput.focus();
      }
    }
  };

  const [missingParamsError, setMissingParamsError] = useState('');

  useEffect(() => {
    if (
      !parseInt(projectId!) ||
      !parseInt(jobId!) ||
      !parseInt(jobVersionId!)
    ) {
      setMissingParamsError('Partial or missing URL parameters');
    }
  }, [jobVersionId, jobId, projectId]);

  const [withRandomSampleOrder, setWithRandomSampleOrder] = useState(true);
  const [submitError, setSubmitError] = useState({
    title: '',
    subTitle: '',
  });
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showTagAllLoaderModal, setShowTagAllLoaderModal] = useState(false);

  const codebookContainerRef = useRef<HTMLDivElement>(null);

  const { data: jobVersionData } = useQuery<JobVersionData, Error>({
    queryKey: ['jobVersion', jobVersionId],
    queryFn: () => getJobVersionApi(jobVersionId!),
    retry: 3,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!jobVersionData) return;

    if (
      [
        JobVersionStatus.PROCESSED,
        JobVersionStatus.PROCESSING,
        JobVersionStatus.FAILED,
      ].includes(jobVersionData.status)
    ) {
      logger.info(
        `jobVersionData.status: ${jobVersionData.status}, navigating to /projects/${projectId}`
      );
      navigate(`/projects/${projectId}`);
    }
    if (jobVersionData.status === JobVersionStatus.ANALYZED) {
      logger.error(
        `unexpected scenario: jobVersionData.status: ${jobVersionData.status}, navigating to /projects/${projectId}`
      );
      navigate(`/projects/${projectId}`);
    }
  }, [jobVersionData, navigate, projectId]);

  const {
    mutateAsync: createOrUpdateConfigMutateAsync,
    isPending: createOrUpdateConfigIsLoading,
    error: createOrUpdateConfigError,
    reset: createOrUpdateConfigReset,
  } = useMutation<any, Error, object>({
    mutationFn: (data) =>
      createOrUpdateJobConfigurationApi(data, jobVersionId!),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['jobConfiguration', jobVersionId],
      });
    },
  });

  const { data: projectData } = useQuery<any, Error>({
    queryKey: ['project', projectId],
    queryFn: () => fetchProjectApi(projectId!),
    retry: 3,
    refetchOnWindowFocus: false,
  });

  const {
    setThemesDraft,
    themesDraft,
    codebookIsDirty,
    isCodebookInFirstVersion,
    setIsCodebookInFirstVersion,
    saveThemes,
    allThemesWithValidNames,
    fetchThemesLoading,
    fetchThemesError,
    saveThemesIsLoading,
    saveThemesError,
    saveThemesReset,
    removeTheme,
    updateTheme,
    addTheme,
    showFetchThemesErrorModal,
    setShowFetchThemesErrorModal,
    downloadThemesFile,
    selectThemesFile,
    showLoadThemesModal,
    setShowLoadThemesModal,
    addSelectedTextToTheme,
    codebookValidationError,
    setCodebookValidationError,
  } = useThemesManager({ jobVersionId, projectName: projectData?.name });

  const {
    tagChangesMap,
    userContentUnderEdit,
    syncLoading,
    syncError,
    resetLoadingAndError,
    addTag,
    removeTag,
    removeThemeTagChanges,
    setUserContentUnderEdit,
    syncCodebookAndTagChanges,
    syncInProgress,
    manualEditsMap,
    manualEditsLoading,
    resetTagChangesMap,
  } = useManualTagManager(jobVersionId);

  const onAddThemeHandler = () => {
    if (hasEmptyNewTheme(themesDraft)) {
      focusLastAddedNameInput();
      return;
    } else {
      addTheme();
    }
  };

  const syncChanges = useCallback(
    async ({
      blocking = false,
      withLoader = true,
    }: { blocking?: boolean; withLoader?: boolean } = {}) => {
      if (syncInProgress) {
        // don't throw on blocking, we'll try to optimistically update and let the api throw if it fails
        logger.error('syncChanges is called while syncInProgress');
      }

      const filteredThemesDraft = filterOutEmptyNewThemes(themesDraft);
      try {
        if (blocking && withLoader) {
          setIsBlockingSyncLoading(true);
        }
        // TODO: syncCodebookAndTagChanges will fail with empty named themes. this is an issue. we might need to skip sync if that's the case
        const localToServerIdMap = await syncCodebookAndTagChanges(
          jobVersionId!,
          filteredThemesDraft
        );

        if (!localToServerIdMap) return themesDraft;

        const updatedThemes = themesDraft.map((theme) => {
          if (theme.localId && localToServerIdMap[theme.localId]) {
            return {
              ...theme,
              localId: undefined,
              id: localToServerIdMap[theme.localId],
            };
          }
          return theme;
        });

        // Update the React state for UI re-render, but return the updated draft for immediate use
        // reverted from setThemesDraft(updatedThemes);
        // the reason for this "duplicate" code is that:
        // 1. we need to return the updated draft for immediate use for tag all
        // 2. passing tagAllMutation as a onComplete callback to syncCodebookAndTagChanges is problematic since it
        // couples react render behavior to the mutation state (e.g. in dev it's called twice in strict mode and causing duplicate tags)
        // 3. using the callback variant of setThemesDraft gaurantees the correct order of state updates.
        // e.g. for state update race condition: 1. call sync api & pause at breakpoint on server 2. change a theme draft 3. resume breakpoint 4. draft gets overriden
        setThemesDraft((old) => {
          const updatedThemes = old.map((theme) => {
            if (theme.localId && localToServerIdMap[theme.localId]) {
              return {
                ...theme,
                localId: undefined,
                id: localToServerIdMap[theme.localId],
              };
            }
            return theme;
          });
          return updatedThemes;
        });
        if (blocking && withLoader) {
          setIsBlockingSyncLoading(false);
        }
        return updatedThemes;
      } catch (error) {
        logger.error('Sync failed:', error);

        if (withLoader) {
          setIsBlockingSyncLoading(false);
        }

        if (blocking) {
          setIsBlockingSyncLoading(false);
          throw error;
        }
        // For non-blocking flow, simply return the current themes draft.
        return themesDraft;
      }
    },
    [
      jobVersionId,
      themesDraft,
      syncCodebookAndTagChanges,
      setThemesDraft,
      syncInProgress,
      setIsBlockingSyncLoading,
    ]
  );

  const refreshCurrentPage = async () => {
    logger.info('refreshCurrentPage - started with themesDraft: ', themesDraft);
    if (showTagAllLoaderModal) {
      logger.info('refreshCurrentPage - tag all is in progress, skipping');
      return;
    }

    const validationError = validateCodebook(themesDraft);
    if (validationError) {
      logger.info('refreshCurrentPage - validationError: ', validationError);
      setCodebookValidationError(true);
      return;
    }

    setIsCodebookInFirstVersion(false);

    if (hasTagChangesOrDirtyCodebook()) {
      await syncChanges();
    }

    await queryClient.resetQueries({
      queryKey: ['themes', jobVersionId],
    });
    await queryClient.resetQueries({
      queryKey: ['tag_page', jobVersionId],
    });
    await queryClient.resetQueries({
      queryKey: ['manualEdits', jobVersionId],
    });
  };

  const {
    data: tagPageData,
    error: tagPageError,
    fetchNextPage,
    hasNextPage,
    isFetching: tagPageIsFetching,
    isLoading: tagPageIsLoading,
    isFetchingNextPage,
    refetch: tagPageRefetch,
  } = useInfiniteQuery({
    queryKey: ['tag_page', jobVersionId, withRandomSampleOrder],
    queryFn: async ({ pageParam, signal }) => {
      logger.info('tag_page queryFn pageParam: ', pageParam);
      return tagPageApi(
        jobVersionId!,
        pageParam,
        withRandomSampleOrder,
        signal
      );
    },
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    initialPageParam: 0,
    enabled: !!themesDraft && themesDraft.length > 0,
    refetchOnWindowFocus: false,
    select: (data) => {
      const res = data.pages.reduce((acc, page) => [...acc, ...page.data], []);
      return res;
    },
  });

  const { tagsCounter, setTagsCounter } = useTagsCounter(
    tagPageData,
    tagChangesMap,
    manualEditsMap,
    themesDraft
  );

  const { filteredData, filters, setFilters } = useFilters(
    tagPageData,
    tagChangesMap,
    manualEditsMap,
    themesDraft
  );

  const { data: jobConfigurationData } = useQuery<any, Error>({
    queryKey: ['jobConfiguration', jobVersionId],
    queryFn: () => getJobConfigurationApi(jobVersionId!),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { data: nonEmptyUserContentCountData } = useQuery<any, Error>({
    queryKey: ['nonEmptyUserContentCount', jobId],
    queryFn: () => getNonEmptyUserContentCount(jobId!),
    retry: 5,
    refetchOnWindowFocus: false,
  });

  const isRestoreVersionEnabled = useIsRestoreVersionEnabled(
    jobId,
    jobVersionId
  );

  const {
    mutate: tagAllMutation,
    // isPending: tagAllIsLoading,
    error: tagAllError,
    reset: tagAllReset,
  } = useMutation<any, Error, Theme[]>({
    mutationFn: (data) => multiTagDataApi(data, jobVersionId!),
    onSuccess: () => {
      setTimeout(() => {
        setShowTagAllLoaderModal(false);
        navigate(`/projects/${projectId}`);
      }, 2000);
    },
    onError: (error) => {
      logger.error('tagAllMutation - error: ', error);
      setShowTagAllLoaderModal(false);
    },
  });

  const {
    toggleTranslate,
    presentTranslation,
    resetTranslationError,
    translateToggleChecked,
    translationIsLoading,
    translationError,
  } = useTranslation(jobVersionId, jobId);

  const { showUpdateAppVersionDialog, refreshAppVersion } = useFormContext();

  const hasTagChangesOrDirtyCodebook = useCallback(() => {
    const hasActiveTagChanges = Object.values(tagChangesMap).some(
      (value) =>
        value.filter((x) => ['pending', 'syncing'].includes(x.status)).length >
        0
    );

    logger.info(
      'hasTagChangesOrCodebookDirty - hasActiveTagChanges: ',
      hasActiveTagChanges
    );

    logger.info(
      'hasTagChangesOrCodebookDirty - codebookIsDirty: ',
      codebookIsDirty
    );

    return hasActiveTagChanges || codebookIsDirty;
  }, [tagChangesMap, codebookIsDirty]);

  usePeriodicCheck({
    idleTime: 5, // Consider idle after 10 seconds
    predicate: hasTagChangesOrDirtyCodebook,
    onAction: syncChanges,
  });

  const onApproveUpdateAppVersion = async () => {
    await saveThemes();
    refreshAppVersion();
  };

  const onRemoveThemeClicked = (index: number, theme: Theme) => {
    removeThemeTagChanges({ themeId: theme.id, themeLocalId: theme.localId });

    setFilters((old) =>
      old.filter((t) => {
        if (theme.id !== undefined) {
          return t.id !== theme.id;
        }

        return t.localId !== theme.localId;
      })
    );

    removeTheme(index);

    setTagsCounter((counter) => ({ ...counter, [theme.name]: 0 }));
  };

  const [showMenu, setShowMenu] = useState(false);
  const menuIconRef = useRef<HTMLImageElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  useOutsideClickHandler([menuIconRef, menuRef], () => {
    setShowMenu(false);
  });

  // TODO: deprecate this or validateCodebook
  const validateForm = () => {
    if (themesDraft.length <= 1) {
      setSubmitError({
        title: 'Codebook Required',
        subTitle:
          'It looks like a codebook is missing. Please load an existing codebook, or allow Blix to generate themes for you. Note that at least two themes are required to proceed',
      });
      return false;
    }

    if (!allThemesWithValidNames()) {
      setSubmitError({
        title: 'Empty Theme Name Detected',
        subTitle:
          'It seems like one or more themes are unnamed. Please ensure all themes have valid names before proceeding',
      });
      return false;
    }

    const validationError = validateCodebook(themesDraft);
    if (validationError) {
      setCodebookValidationError(true);
      return false;
    }

    return true;
  };

  const tagAll = async () => {
    logger.info('tagAll - started');
    const validationError = validateCodebook(themesDraft);
    if (validationError) {
      logger.info('tagAll - validationError: ', validationError);
      setCodebookValidationError(true);
      return;
    }

    if (!validateForm()) {
      return;
    }

    setShowTagAllLoaderModal(true);

    try {
      let updatedThemes = themesDraft;
      if (hasTagChangesOrDirtyCodebook()) {
        logger.info('tagAll - hasTagChangesOrDirtyCodebook - syncing');
        updatedThemes = await syncChanges({
          blocking: true,
          withLoader: false,
        });

        logger.info(
          'tagAll - syncChanges - done with updatedThemes: ',
          updatedThemes
        );
      }

      if (!updatedThemes) {
        logger.error('tagAll - unexpected scenario - no updatedThemes');
        return;
      }

      tagAllMutation(updatedThemes);
    } catch (error) {
      logger.error('tagAll - syncChanges - error: ', error);
      setShowTagAllLoaderModal(false);
      setShowUnsavedDataWarningMetadata({
        showModal: true,
        onApprove: async () => {
          setShowTagAllLoaderModal(true);
          tagAllMutation(themesDraft);
        },
      });
    }
  };

  const closeAdvancedSettingsModal = async (
    params: OnCloseFunctionTypeParams
  ) => {
    await createOrUpdateConfigMutateAsync(params);
    setShowSettingsModal(false);
  };

  const onScrollToEndOfTagResults = () => {
    if (hasNextPage && !tagPageIsFetching && !showTagAllLoaderModal) {
      logger.info('onScrollToEndOfTagResults: calling fetchNextPage');
      fetchNextPage();
    }
  };

  async function handleCheckboxChange(event: any) {
    logger.info('handleCheckboxChange - started');
    const checked = event.target.checked;
    setWithRandomSampleOrder(checked);
    if (checked) {
      await reshuffleTagSampleOrderApi(jobVersionId!);
    }

    await queryClient.resetQueries({
      queryKey: ['tag_page', jobVersionId],
    });
  }

  const navigateBack = async () => {
    const validationError = validateCodebook(themesDraft);
    if (validationError) {
      logger.info('navigateBack - validationError: ', validationError);
      setCodebookValidationError(true);
      return;
    }

    try {
      if (hasTagChangesOrDirtyCodebook()) {
        await syncChanges({ blocking: true });
      }

      navigate(`/projects/${projectId}/${jobId}/${jobVersionId}/setup`);
    } catch (error) {
      logger.error('navigateBack - syncChanges - error: ', error);
      setShowUnsavedDataWarningMetadata({
        showModal: true,
        onApprove: async () => {
          navigate(`/projects/${projectId}/${jobId}/${jobVersionId}/setup`);
        },
      });
    }
  };

  const closeTagAllError = () => {
    tagAllReset();
    navigate(`/projects/${projectId}`);
  };

  // onContinueAnywayOnError?: in case of error, we display an error modal with a continue anyway button; this is the callback for that button
  const blockingSyncChanges = async (onContinueAnywayOnError?: () => void) => {
    const validationError = validateCodebook(themesDraft);
    if (validationError) {
      logger.info('blockingSyncChanges - validationError: ', validationError);
      setCodebookValidationError(true);
      return;
    }

    try {
      if (hasTagChangesOrDirtyCodebook()) {
        await syncChanges({ blocking: true });
      }
    } catch (error) {
      logger.error('blockingSyncChanges - syncChanges - error: ', error);
      setShowUnsavedDataWarningMetadata({
        showModal: true,
        onApprove: async () => {
          onContinueAnywayOnError?.();
        },
      });

      // rethrow the error so it bubbles up to the caller, so we break the flow
      throw error;
    }
  };

  return (
    <Container>
      {/* <CenteredHorizontalFlex>
        <PrimaryButton
          label='Trigger Codebook & Manual Edits Sync'
          onClick={syncChanges}
        />
        <PrimaryButton
          label='Reset Manual Edits'
          onClick={() => resetManualEditsMutateAsync()}
        />
      </CenteredHorizontalFlex> */}
      <PageHeader
        beforeNavigate={blockingSyncChanges}
        beforeLogout={blockingSyncChanges}
      />
      <ContentsContainer>
        <label style={{ width: 200, display: 'none' }}>
          With Random Order
          <input
            type='checkbox'
            onChange={handleCheckboxChange}
            defaultChecked={true}
          />
        </label>
        <PageHeadersContainer>
          <HorizontalFlex>
            <BackButtonWithActive
              icon={<StyledBackIcon />}
              onClick={navigateBack}
            />
            <VerticalFlex>
              <H1>Refine Your Codebook</H1>
              <SubTitle>
                Customize and refine your collection of themes by deleting,
                renaming, or adding new ones as needed.
              </SubTitle>
            </VerticalFlex>
          </HorizontalFlex>
          <PageHeaderRightContainer>
            <PrimaryButton
              // disabled
              label='Apply tags to all data'
              onClick={tagAll}
              icon={<img src={MagicWandIcon} />}
            />
            <StyledMenuIcon
              ref={menuIconRef}
              src={ThreeDotsMenuIcon}
              onClick={(e) => {
                e.stopPropagation();
                setShowMenu((x) => !x);
              }}
              $clicked={showMenu}
            />
            {showMenu && (
              <StyledMenu $visible={showMenu} ref={menuRef}>
                <MenuButton
                  text='Download Codebook'
                  icon={<MenuItemIcon src={DownloadIcon} />}
                  onClick={downloadThemesFile}
                />
                {isRestoreVersionEnabled && (
                  <RestoreVersionMenu
                    projectId={projectId}
                    jobId={jobId}
                    activeJobVersionId={jobVersionId}
                    setShowMenu={setShowMenu}
                  />
                )}
                <Separator />
                <TranslateToggle
                  label='Translate Verbatim'
                  onChange={toggleTranslate}
                  checked={translateToggleChecked}
                  loading={translationIsLoading}
                  icon={<MenuItemIcon src={TranslateIcon} />}
                />
              </StyledMenu>
            )}
          </PageHeaderRightContainer>
        </PageHeadersContainer>
        <TopContainer>
          <CodebookContainer ref={codebookContainerRef}>
            <CodebookControlBar
              onClickDownloadCodebook={downloadThemesFile}
              onClickAddTheme={onAddThemeHandler}
              themeCount={themesDraft.length}
            />
            <ThemesList
              nameInputRefs={themesListNameInputRefs}
              tagsCounter={tagsCounter}
              themes={themesDraft}
              onRemoveTheme={onRemoveThemeClicked}
              onUpdateTheme={updateTheme}
              onAddTheme={onAddThemeHandler}
              resultsCount={tagPageData?.length}
              focusLastAddedNameInput={focusLastAddedNameInput}
            />
          </CodebookContainer>
          <TagResultsContainer>
            <StyledTagResultsWithScrollToEnd
              filters={filters}
              setFilters={setFilters}
              themesDraft={filterOutEmptyNewThemes(themesDraft)}
              results={filteredData}
              nonEmptyUserContentCount={nonEmptyUserContentCountData?.count}
              onScrollToEnd={onScrollToEndOfTagResults}
              isFetchingNextPage={isFetchingNextPage}
              pageIsLoading={tagPageIsLoading}
              createTheme={addTheme}
              addSelectedTextToTheme={addSelectedTextToTheme}
              refreshCurrentPage={refreshCurrentPage}
              codebookLanguage={jobConfigurationData?.codebook_language}
              isCodebookInFirstVersion={isCodebookInFirstVersion}
              translationEnabled={presentTranslation}
              tagChangesMap={tagChangesMap}
              userContentUnderEdit={userContentUnderEdit}
              syncLoading={syncLoading && false}
              syncError={syncError}
              resetLoadingAndError={resetLoadingAndError}
              addTag={addTag}
              removeTag={removeTag}
              setUserContentUnderEdit={setUserContentUnderEdit}
              manualEditsMap={manualEditsMap}
              manualEditsLoading={manualEditsLoading}
            />
          </TagResultsContainer>
        </TopContainer>
        {showSettingsModal && (
          <CodebookAdvancedSettingsModal
            hidden={!showSettingsModal}
            onClose={closeAdvancedSettingsModal}
            existingTagExampleText={jobConfigurationData?.prompt_example_string}
          />
        )}
        {showUpdateAppVersionDialog && (
          <MessageModal
            withCloseButton={false}
            show={showUpdateAppVersionDialog}
            approveButtonText='OK'
            onApprove={onApproveUpdateAppVersion}
            title='New Blix Version'
            subTitle='Great news! A new version of Blix is here! Click OK to update now – your work is saved.'
          />
        )}
        <LoadThemesModal
          hidden={!showLoadThemesModal}
          onClose={() => setShowLoadThemesModal(false)}
          onSelectFile={selectThemesFile}
        />
        <LoadingModal
          hidden={!showTagAllLoaderModal}
          title='Processing all data...'
        />
        <LoadingModal
          title='Saving your analysis config...'
          hidden={!createOrUpdateConfigIsLoading}
        />
        <LoadingModal
          hidden={!saveThemesIsLoading}
          title='Saving Codebook...'
        />
        <LoadingModal
          hidden={!isBlockingSyncLoading}
          title={blockingSyncLoadingMessage}
        />
        <LoadingModal hidden={!fetchThemesLoading} title='Loading Themes...' />

        <ErrorModal
          show={!!tagAllError}
          onClose={closeTagAllError}
          title={'Tag All Error'}
          subTitle={tagAllError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={!!missingParamsError}
          onClose={async () => {
            setMissingParamsError('');
            navigate(-1);
          }}
          title={'Missing Url Params'}
          subTitle={missingParamsError}
        />
        <ErrorModal
          show={!!translationError}
          onClose={resetTranslationError}
          title={'Translation Error'}
          subTitle={
            'Oops! It looks like we encountered an issue translating your data. Please try again. If the problem persists, contact our support team for assistance.'
          }
        />
        <ErrorModal
          show={!!createOrUpdateConfigError}
          onClose={createOrUpdateConfigReset}
          title={'Project Settings Error'}
          subTitle={createOrUpdateConfigError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={showFetchThemesErrorModal}
          onClose={() => setShowFetchThemesErrorModal(false)}
          title={'Fetching Themes Error'}
          subTitle={fetchThemesError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={!!saveThemesError}
          onClose={saveThemesReset}
          title={'Saving Codebook Error'}
          subTitle={saveThemesError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={!!tagPageError}
          onClose={tagPageRefetch}
          title={'Tag Sample Error'}
          subTitle={tagPageError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={!!submitError.title}
          onClose={() => setSubmitError({ title: '', subTitle: '' })}
          title={submitError.title}
          subTitle={submitError.subTitle}
        />
        <ErrorModal
          show={!!codebookValidationError}
          onClose={() => setCodebookValidationError(false)}
          title={'Empty Theme Name Detected'}
          subTitle={
            'It seems like one or more themes are unnamed. Please ensure all themes have valid names before proceeding'
          }
        />
        <BeforeUnloadUnsavedDataModal
          shouldWarnUser={hasTagChangesOrDirtyCodebook}
          optimisticUpdate={async () => {
            await syncChanges({ withLoader: false, blocking: true });
          }}
        />
        <MessageModal
          show={showUnsavedDataWarningMetadata.showModal}
          title="Oops! We couldn't save your changes"
          subTitle={continureAnywayErrorMessage}
          approveButtonText='Continue anyway'
          onApprove={async () => {
            await showUnsavedDataWarningMetadata.onApprove?.();
            resetTagChangesMap();
            setShowUnsavedDataWarningMetadata({
              showModal: false,
            });
          }}
          cancelButtonText='Cancel'
          onClose={async () => {
            await showUnsavedDataWarningMetadata.onClose?.();
            await syncChanges({ withLoader: false });
            setShowUnsavedDataWarningMetadata({
              showModal: false,
            });
          }}
        />
      </ContentsContainer>
    </Container>
  );
};

export default SplitViewCodebookEditorPage;

const Container = styled(CenteredVerticalFlex)`
  flex: 1;
  overflow-y: hidden;
`;

const TopContainer = styled(HorizontalFlex)`
  flex: 1;
  gap: 32px;
  overflow-y: hidden;
`;

const StyledMenuIcon = styled(MenuIcon)`
  position: unset;
`;

const StyledMenu = styled(Menu)`
  right: 0px;
  width: 262px;
`;

const ContentsContainer = styled(CenteredVerticalFlex)`
  flex: 1;
  width: 95%;
  max-width: calc(669px + 32px + 899px);
  margin-bottom: 24px;
  align-items: stretch;
  margin: 0 32px;
  overflow-y: hidden;
`;

const PageHeadersContainer = styled(HorizontalFlex)`
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 0;
`;

const PageHeaderRightContainer = styled(HorizontalFlex)`
  position: relative;
  align-items: center;
  gap: 8px;
`;

const TranslateToggle = styled(ToggleInputWithLabel)`
  padding: 16px 24px;
`;

const CodebookContainer = styled(VerticalFlex)``;

const TagResultsContainer = styled(VerticalFlex)`
  flex-grow: 1;
  max-width: calc(100vw - 995px);
  overflow-y: hidden;
`;

const StyledTagResultsWithScrollToEnd = styled(TagResultsWithScrollToEnd)`
  background-color: ${Colors.P5};
`;

const CommonButtonStlye = `
  width: 32px;
  height: 32px;
  border-radius: 8px;
`;

const StyledBackIcon = styled(BackIcon)`
  ${CommonButtonStlye};
`;

const BackButtonWithActive = styled(StyledButtonWithActive)`
  padding: 0 16px 16px 0;
  justify-content: flex-start;
  > svg:hover {
    background-color: ${Colors.P10};
  }
  > svg {
    border: 1px solid ${Colors.P100};
  }
  &:active > svg path {
    fill: ${Colors.WHITE};
  }
`;

const SubTitle = styled(P1)`
  margin-top: 8px;
  color: ${Colors.B60};
`;

const MenuItemIcon = styled.img`
  width: 20px;
  height: 20px;
`;
