import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import BlixLogoIcon from '../../assets/logo.svg';
import { Colors } from '../../theme/Colors';

interface DashboardHeaderProps {
  title: string;
  logoUrl?: string;
}

const DashboardHeader: React.FC<PropsWithChildren<DashboardHeaderProps>> = ({
  title,
  logoUrl,
}) => {
  return (
    <HeaderContainer>
      <Logo src={logoUrl || BlixLogoIcon} alt='logo' />
      <Title>{title}</Title>
    </HeaderContainer>
  );
};

export default DashboardHeader;

const Title = styled.h1`
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${Colors.B100};
  font-size: 3em;
`;

const HeaderContainer = styled.div`
  position: relative;
  padding-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  position: absolute;
  left: 0;
  margin-bottom: 8px;
  margin-left: 50px;
  object-fit: contain;
  width: 56px;
  height: 56px;
`;
