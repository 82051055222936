import { ManualEdit, Tag, TagChange, TaggedUserContent, Theme } from '../types';

export const compareThemeLists = (themes1: Theme[], themes2: Theme[]) => {
  if (themes1.length !== themes2.length) {
    return false;
  }

  const sortedThemes1 = themes1.sort(
    // @ts-ignore
    (a, b) => (a.id ?? a.localId) - (b.id ?? b.localId)
  );
  const sortedThemes2 = themes2.sort(
    // @ts-ignore
    (a, b) => (a.id ?? a.localId) - (b.id ?? b.localId)
  );

  for (let i = 0; i < sortedThemes1.length; i++) {
    const t1 = sortedThemes1[i];
    const t2 = sortedThemes2[i];

    if (!areThemeIdsEqual(t1, t2)) {
      return false;
    }

    const differentNames = t1.name !== t2.name;
    const oneEmptyOrUndefinedAndOnePopulated =
      !!t1.instructions !== !!t2.instructions;
    const bothPopulatedWithDifferentValues =
      !!t1.instructions &&
      !!t2.instructions &&
      t1.instructions !== t2.instructions;

    if (
      differentNames ||
      oneEmptyOrUndefinedAndOnePopulated ||
      bothPopulatedWithDifferentValues
    ) {
      return false;
    }
  }

  return true;
};

export const areThemeIdsEqual = (
  t1: Pick<Theme, 'id' | 'localId'>,
  t2: Pick<Theme, 'id' | 'localId'>
) => {
  if (t1.id !== undefined || t2.id !== undefined) {
    return t1.id === t2.id;
  }

  return t1.localId === t2.localId;
};

// manual edits & system tags relate only to persisted themes, i.e. themes with id, not localId
export const getUserContentAddedTagsLocalAndPersistedThemeIds = (
  userContent: TaggedUserContent,
  manualEdits: ManualEdit[] = [],
  tagChanges: TagChange[] = []
): Pick<Theme, 'id' | 'localId'>[] => {
  const activeTagChanges = getActiveTagChanges(tagChanges);

  const addedTagChangesThemeIds = activeTagChanges
    .filter((change) => change.action === 'add')
    .map((a) => ({ id: a.themeId, localId: a.themeLocalId }));

  const tagChangesThemeIds = activeTagChanges.map(
    (a) => a.themeId || a.themeLocalId
  );

  const manuallyAddedThemesNotHandledByTagChanges = manualEdits
    .filter((edit) => edit.type === 'add')
    .filter((edit) => !tagChangesThemeIds.includes(edit.themeId))
    .map((edit) => ({ id: edit.themeId }));

  const manuallyHandledThemeIds = activeTagChanges
    .map((change) => change.themeId ?? change.themeLocalId)
    .concat(manualEdits.map((edit) => edit.themeId));

  const systemAddedThemes = userContent.tags
    .filter((tag: Tag) => !manuallyHandledThemeIds?.includes(tag.themeId))
    .map((tag: Tag) => ({ id: tag.themeId }));

  return [
    ...addedTagChangesThemeIds,
    ...manuallyAddedThemesNotHandledByTagChanges,
    ...systemAddedThemes,
  ];
};

// active tag changes are 'pending' or 'syncing' tag changes that are not reflected in the manual edits yet
export const isActiveTagChange = (change: TagChange) => {
  return change.status === 'pending' || change.status === 'syncing';
};

const getActiveTagChanges = (tagChanges: TagChange[]) => {
  const activeTagChanges = tagChanges.filter(isActiveTagChange);
  return activeTagChanges;
};

const getActiveTagChangesThemeIds = (tagChanges: TagChange[]) => {
  const activeTagChangesThemeIds = getActiveTagChanges(tagChanges).map(
    (change) => change.themeId ?? change.themeLocalId
  );

  return activeTagChangesThemeIds;
};

const getSortedActiveAddTagChangesThemeIds = (tagChanges: TagChange[]) => {
  const activeAddTagChangesThemeIds = getActiveTagChanges(tagChanges)
    .filter((change) => change.action === 'add')
    .sort((a, b) => a.createdAt - b.createdAt)
    .map((change) => change.themeId ?? change.themeLocalId);

  return activeAddTagChangesThemeIds;
};

const getManualEditsThemeIds = (manualEdits: ManualEdit[] = []) => {
  const manualEditsThemeIds = manualEdits.map((edit) => edit.themeId);
  return manualEditsThemeIds;
};

export const getActiveTagChangesAddedThemes = (
  themes: Theme[],
  tagChanges: TagChange[]
) => {
  const activeTagChangesThemeIds =
    getSortedActiveAddTagChangesThemeIds(tagChanges);

  const pendingManuallyAddedThemes = activeTagChangesThemeIds.map((id) =>
    themes.find((theme) => theme.id === id || theme.localId === id)
  );

  return pendingManuallyAddedThemes;
};

const getManuallyAddedThemeIdsNotHandledByTagChanges = (
  manualEdits: ManualEdit[],
  tagChanges: TagChange[]
) => {
  const pendingTagChangesThemeIds = getActiveTagChangesThemeIds(tagChanges);

  const manuallyAddedThemeIdsNotHandledByTagChanges = manualEdits
    .filter((edit) => edit.type === 'add')
    .map((edit) => edit.themeId)
    .filter((themeId) => !pendingTagChangesThemeIds.includes(themeId));

  return manuallyAddedThemeIdsNotHandledByTagChanges;
};

export const shouldDisplayManualOrPersistedOther = (
  taggedUserContent: TaggedUserContent,
  themes: Theme[],
  manualEdits: ManualEdit[] = [],
  tagChanges: TagChange[] = []
) => {
  const persistedThemeIds = themes
    .filter((t) => t.id !== undefined)
    .map((theme) => theme.id) as number[];

  const systemTagsNotHandledManually = getSystemTagsNotHandledManually(
    taggedUserContent,
    manualEdits,
    tagChanges,
    persistedThemeIds
  );

  const filteredManuallyAddedThemes = getFilteredManuallyAddedThemes(
    themes,
    manualEdits,
    tagChanges
  );

  const activeTagChangesAddedThemes = getActiveTagChangesAddedThemes(
    themes,
    tagChanges
  );

  const shouldDisplayPersistedOther =
    taggedUserContent.tags.some((tag) => tag.themeName === 'Other') &&
    activeTagChangesAddedThemes.length === 0 &&
    filteredManuallyAddedThemes.length === 0;

  const shouldDisplayManualOther =
    !shouldDisplayPersistedOther &&
    activeTagChangesAddedThemes.length === 0 &&
    filteredManuallyAddedThemes.length === 0 &&
    systemTagsNotHandledManually.length === 0;

  return {
    shouldDisplayManualOther,
    shouldDisplayPersistedOther,
  };
};

export const getFilteredManuallyAddedThemes = (
  themes: Theme[],
  manualEdits: ManualEdit[],
  tagChanges: TagChange[]
) => {
  const manuallyAddedThemeIdsNotHandledByTagChanges =
    getManuallyAddedThemeIdsNotHandledByTagChanges(manualEdits, tagChanges);

  const filteredManuallyAddedThemes =
    manuallyAddedThemeIdsNotHandledByTagChanges
      .map((themeId) => {
        const theme = themes.find((theme) => theme.id === themeId);
        return theme;
      })
      .filter((theme) => theme !== undefined);

  return filteredManuallyAddedThemes;
};

const getManuallyHandledThemeIds = (
  tagChanges: TagChange[] = [],
  manualEdits: ManualEdit[] = []
) => {
  const manuallyHandledThemeIds = getActiveTagChangesThemeIds(
    tagChanges
  ).concat(getManualEditsThemeIds(manualEdits));

  return manuallyHandledThemeIds;
};

export const getSystemTagsNotHandledManually = (
  taggedUserContent: TaggedUserContent,
  manualEdits: ManualEdit[],
  tagChanges: TagChange[],
  persistedThemeIds: number[]
) => {
  const manuallyHandledThemeIds = getManuallyHandledThemeIds(
    tagChanges,
    manualEdits
  );

  const systemTagsNotHandledManually = taggedUserContent.tags
    .filter((tag) => persistedThemeIds.includes(tag.themeId))
    .filter((tag) => !manuallyHandledThemeIds?.includes(tag.themeId));

  return systemTagsNotHandledManually;
};

export const hasEmptyNewTheme = (themes: Theme[]) => {
  return themes.some((theme) => !theme.id && !theme.name);
};

export const filterOutEmptyNewThemes = (themes: Theme[]) => {
  return themes.filter((theme) => theme.id || theme.name);
};

export const hasPersistedThemeWithEmptyName = (themes: Theme[]) => {
  return themes.some((theme) => theme.id && !theme.name);
};

export const hasThemesWithInstructionsAndEmptyName = (themes: Theme[]) => {
  return themes.some((theme) => theme.instructions && !theme.name);
};

export const validateCodebook = (themes: Theme[]) => {
  if (hasPersistedThemeWithEmptyName(themes)) {
    return 'Theme names cannot be empty, please fix the theme names and try again';
  }

  if (hasThemesWithInstructionsAndEmptyName(themes)) {
    return 'Theme names cannot be empty, please fix the theme names and try again';
  }

  return;
};
