import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import CenteredVerticalFlex from '../containers/CenteredVerticalFlex';
import HorizontalFlex from '../containers/HorizontalFlex';
import P1 from '../newTextComponents/P1';
import { p1TextStyle } from '../newTextComponents/SharedTextStyles';

interface OtherThemeItemProps {
  tagSampleCounter?: number;
  totalTagsCount?: number;
}

const OtherThemeItem = React.forwardRef<
  HTMLInputElement | null,
  OtherThemeItemProps
>(({ tagSampleCounter, totalTagsCount }, ref) => {
  const [width, setWidth] = useState(0);
  const spanRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    if (spanRef?.current) {
      spanRef.current.textContent = 'Other';
      const width = spanRef.current.offsetWidth;
      setWidth(width);
    }
  }, [spanRef]);

  return (
    <ItemContainer>
      <HiddenSpan ref={spanRef} />
      <HeaderContainer>
        <TitleContainer>
          <NameInput
            style={{
              width,
            }}
            disabled
            type='text'
            autoFocus
            ref={ref}
            value={'Other'}
            onChange={() => {}}
          />
          {tagSampleCounter && totalTagsCount ? (
            <ThemeRatioContainer>
              <ThemeRatioLabel>
                {Math.round((100 * tagSampleCounter) / totalTagsCount)}%
              </ThemeRatioLabel>
            </ThemeRatioContainer>
          ) : (
            ''
          )}
        </TitleContainer>
      </HeaderContainer>
      <BlankInstructions />
    </ItemContainer>
  );
});

const HeaderContainer = styled(HorizontalFlex)`
  justify-content: space-between;
  margin-bottom: 8px;
`;

const HiddenSpan = styled.span`
  visibility: hidden;
  position: fixed;
  white-space: pre;
`;

const ThemeRatioContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ThemeRatioLabel = styled(P1)`
  color: ${Colors.P200};
`;

const TitleContainer = styled(HorizontalFlex)``;

const ItemContainer = styled(CenteredVerticalFlex)`
  flex-shrink: 0;
  border-radius: 16px;
  align-items: stretch;
`;

const NameInput = styled.input`
  cursor: no-drop;
  ${p1TextStyle};
  font-weight: 500;
  color: ${Colors.B100};
  border: none;
  outline: none;
  background-color: transparent;
  min-width: 50px;
  max-width: 320px;
  box-sizing: border-box;
`;

const BlankInstructions = styled.div`
  height: 93px;
  border-radius: 8px;
  background-color: ${Colors.B10};
  border: 1px solid ${Colors.B20};
`;
export default OtherThemeItem;
