import { useMutation, useQuery } from '@tanstack/react-query';
import Papa from 'papaparse';
import { useCallback, useEffect, useRef, useState } from 'react';
import { fetchThemesApi, saveThemesApi } from '../api/pipelineApi';
import { Theme } from '../types';
import { downloadThemesFileUtil } from '../utils/csv-utils';
import logger from '../utils/logger';
import {
  areThemeIdsEqual,
  compareThemeLists,
  filterOutEmptyNewThemes,
  hasEmptyNewTheme,
  validateCodebook,
} from '../utils/theme-utils';

export interface UseThemesManagerProps {
  jobVersionId: string;
  projectName?: string;
}

function useThemesManager({
  jobVersionId,
  projectName,
}: UseThemesManagerProps) {
  const [showLoadThemesModal, setShowLoadThemesModal] = useState(false);
  const [themesDraft, setThemesDraft] = useState<Theme[]>([]);
  const [codebookIsDirty, setCodebookIsDirty] = useState(false);
  const [isCodebookInFirstVersion, setIsCodebookInFirstVersion] =
    useState(true);
  const [showFetchThemesErrorModal, setShowFetchThemesErrorModal] =
    useState(false);

  const [codebookValidationError, setCodebookValidationError] = useState(false);

  // Fetch themes
  const {
    data: fetchThemesData,
    isPending: fetchThemesLoading,
    error: fetchThemesError,
  } = useQuery<Theme[], Error>({
    queryKey: ['themes', jobVersionId],
    queryFn: () => fetchThemesApi(jobVersionId),
    retry: 2,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (fetchThemesError) {
      logger.error('fetchThemesError: ', fetchThemesError);
      setShowFetchThemesErrorModal(true);
    }
  }, [fetchThemesError]);

  // Save themes mutation
  const {
    mutateAsync: saveThemesMutateAsync,
    isPending: saveThemesIsLoading,
    error: saveThemesError,
    reset: saveThemesReset,
  } = useMutation<any, Error, Theme[]>({
    mutationFn: (themes) => saveThemesApi(themes, jobVersionId!),
    onSuccess: (data) => {
      logger.info('saveThemesMutateAsync: onSuccess data:', data);
      // invalidate themes remove since it's affecting the codebook 'dirty' state hence blocks refresh current page
      // queryClient.invalidateQueries({
      //   queryKey: ['themes', jobVersionId],
      // });
    },
  });

  const oneTimeEffect = useRef(false);
  // Update themes when fetched
  useEffect(() => {
    if (fetchThemesData && !oneTimeEffect.current) {
      oneTimeEffect.current = true;
      logger.info('useThemesManager - updating themesDraft: ', fetchThemesData);
      setThemesDraft(fetchThemesData);
    }
  }, [fetchThemesData]);

  // Track dirty state
  useEffect(() => {
    if (!fetchThemesData) return;

    const cleanThemesDraft = filterOutEmptyNewThemes(themesDraft);
    const equal = compareThemeLists(fetchThemesData, cleanThemesDraft);
    setCodebookIsDirty(!equal);
  }, [fetchThemesData, themesDraft]);

  const removeTheme = (index: number) => {
    // TODO: remove theme by id
    setThemesDraft((old) => old.filter((_, i) => i !== index));
  };

  const updateTheme = (index: number, update: Partial<Theme>) => {
    setThemesDraft((old) => {
      const newThemes = [...old];
      const oldTheme = newThemes[index];
      const merged = { ...oldTheme, ...update };
      newThemes[index] = merged;
      return newThemes;
    });
  };

  const addTheme = (themeName?: string, instructions?: string) => {
    logger.info(
      `addTheme: themeName ${themeName} instructions ${instructions}`
    );

    const localId = Date.now();

    setThemesDraft((old) => [
      ...old,
      {
        localId,
        name: themeName ?? '',
        instructions: instructions ? `Include: ${instructions}` : '',
      },
    ]);

    return localId;
  };

  const addSelectedTextToTheme = (text: string, theme: Theme) => {
    setThemesDraft((old) => {
      const newThemes = old.map((t) => {
        if (areThemeIdsEqual(t, theme)) {
          const newTheme = { ...t };
          newTheme.instructions = t.instructions
            ? `${t.instructions}, ${text}`
            : `Include: ${text}`;

          return newTheme;
        }

        return t;
      });

      return newThemes;
    });
  };

  const allThemesWithValidNames = () =>
    themesDraft.every((theme) => theme.name?.length > 0);

  const persistThemes = async () => {
    logger.info('persistThemes - started with themesDraft: ', themesDraft);
    const validationError = validateCodebook(themesDraft);
    if (validationError) {
      logger.info('persistThemes - validationError: ', validationError);
      setCodebookValidationError(true);
      return;
    }

    if (hasEmptyNewTheme(themesDraft)) {
      setCodebookValidationError(true);
      return;
    }

    const update = await saveThemesMutateAsync(themesDraft);
    logger.info('persistThemes - update: ', update);
  };

  const downloadThemesFile = async () => {
    logger.info('downloadThemesFile - started with themesDraft: ', themesDraft);
    const validationError = validateCodebook(themesDraft);
    if (validationError) {
      logger.info('downloadThemesFile - validationError: ', validationError);
      setCodebookValidationError(true);
      return;
    }

    if (hasEmptyNewTheme(themesDraft)) {
      setCodebookValidationError(true);
      return;
    }

    const update = await saveThemesMutateAsync(themesDraft);
    downloadThemesFileUtil(update, `${projectName || 'New_Research'}_codebook`);
  };

  const selectThemesFile = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) {
      logger.info("couldn't find file");
      return;
    }

    Papa.parse(file, {
      header: true,
      complete: function (results) {
        const localThemes = results.data as Theme[];
        setThemesDraft((old) => [...old, ...localThemes]);
        setShowLoadThemesModal(false);
      },
    });
  }, []);

  return {
    setThemesDraft,
    themesDraft,
    codebookIsDirty,
    fetchThemesLoading,
    fetchThemesError,
    saveThemesIsLoading,
    saveThemesError,
    saveThemesReset,
    removeTheme,
    updateTheme,
    addTheme,
    addSelectedTextToTheme,
    allThemesWithValidNames,
    downloadThemesFile,
    selectThemesFile,
    saveThemes: persistThemes,
    isCodebookInFirstVersion,
    setIsCodebookInFirstVersion,
    showFetchThemesErrorModal,
    setShowFetchThemesErrorModal,
    showLoadThemesModal,
    setShowLoadThemesModal,
    codebookValidationError,
    setCodebookValidationError,
  };
}

export default useThemesManager;
