import { useQuery } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { signOut } from 'supertokens-auth-react/recipe/session';
import { getOrganizationApi } from '../api/pipelineApi';
import AvatarIcon from '../assets/avatar.svg';
import LogoIcon from '../assets/logo_with_name.svg';
import useOutsideClickHandler from '../hooks/useOutsideClickHandler';
import { useUser } from '../hooks/useUser';
import { Colors } from '../theme/Colors';
import { FontFamily } from '../theme/FontFamily';
import { removeUser } from '../utils/userLocalStorage';
import Breadcrumbs from './Breadcrumbs';
import Label from './Label';
import ButtonWithIcon from './buttons/ButtonWithIcon';
import PrimaryButton from './buttons/PrimaryButton';
import CenteredVerticalFlex from './containers/CenteredVerticalFlex';
import HorizontalFlex from './containers/HorizontalFlex';

interface PageHeaderProps {
  text?: string;
  onClick?: VoidFunction;
  beforeNavigate?: (onContinueAnywayOnError?: () => void) => Promise<void>;
  beforeLogout?: (onContinueAnywayOnError?: () => void) => Promise<void>;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  text,
  onClick,
  beforeNavigate,
  beforeLogout,
}) => {
  const navigate = useNavigate();

  const user = useUser();
  const orgId = user?.organization_id;

  const { data: organizationData } = useQuery<any, Error>({
    queryKey: ['organization', orgId],
    queryFn: () => getOrganizationApi(orgId!),
    retry: 3,
    refetchOnWindowFocus: false,
    enabled: !!orgId,
  });

  async function onLogout() {
    const onComplete = async () => {
      setIsMenuOpen(false);
      removeUser();
      await signOut();
      window.location.href = '/';
    };

    await beforeLogout?.(onComplete);
    await onComplete();
  }

  const onClickLogo = async () => {
    await beforeNavigate?.(() => navigate('/projects'));
    navigate('/projects');
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  const avatarMenuRef = useRef<HTMLDivElement>(null);

  useOutsideClickHandler([avatarMenuRef], () => {
    setIsMenuOpen(false);
  });

  return (
    <HeaderContainer>
      <LogoContainer>
        <LeftContainer>
          <Logo src={LogoIcon} onClick={onClickLogo} alt='logo' />
          <Breadcrumbs beforeNavigate={beforeNavigate} />
        </LeftContainer>
        <MenuContainer ref={avatarMenuRef}>
          <AvatarButton
            icon={
              <img
                src={AvatarIcon}
                style={{ width: 29, height: 32, borderRadius: 16 }}
              />
            }
            onClick={toggleMenu}
          ></AvatarButton>
          {isMenuOpen && (
            <PopupMenu>
              <Label>{organizationData.name}</Label>
              <LogoutButton
                withClearBackground
                label='Logout'
                onClick={onLogout}
                textStyle={{ color: Colors.LINK_BLUE, fontWeight: 500 }}
              />
            </PopupMenu>
          )}
        </MenuContainer>
      </LogoContainer>
      <HorizontalFlex>
        {text ? <Header onClick={onClick}>{text}</Header> : null}
      </HorizontalFlex>
    </HeaderContainer>
  );
};
export default PageHeader;

const AvatarButton = styled(ButtonWithIcon)`
  padding: 0;
`;

const LeftContainer = styled(HorizontalFlex)`
  gap: 32px;
`;

const LogoContainer = styled(HorizontalFlex)`
  padding: 16px 24px;
  align-self: stretch;
  justify-content: space-between;
`;

const HeaderContainer = styled(CenteredVerticalFlex)`
  align-self: stretch;
`;

const Header = styled.span`
  margin-top: 32px;
  font-family: ${FontFamily.ROBOTO};
  /* font-style: normal; */
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  color: ${Colors.GRAY_100};
  text-align: center;
`;

const Logo = styled.img`
  width: 82.051px;
  height: 32px;
  flex-shrink: 0;
  cursor: pointer;
`;

const MenuContainer = styled.div`
  position: relative;
`;

const PopupMenu = styled(CenteredVerticalFlex)`
  align-items: flex-start;
  position: absolute;
  padding: 16px;
  right: 0;
  top: 76px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;

  ::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 70%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
  }
`;

const LogoutButton = styled(PrimaryButton)`
  border: none;
  padding: 0;
`;
