import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import P2 from './P2';

interface MediumTextWithIconProps {
  text: string;
  icon: ReactElement;
  className?: string;
  variant?: 'primary' | 'secondary' | 'bright' | 'disabled';
}

const MediumTextWithIcon: React.FC<MediumTextWithIconProps> = ({
  text,
  icon,
  className,
  variant = 'secondary',
}) => {
  return (
    <Container className={className}>
      {icon}
      <Label variant={variant}>{text}</Label>
    </Container>
  );
};

export default MediumTextWithIcon;

const Container = styled(CenteredHorizontalFlex)`
  gap: 4px;
`;

const Label = styled(P2)<{
  variant: 'primary' | 'secondary' | 'bright' | 'disabled';
}>`
  font-weight: 500;
  color: ${({ variant }) =>
    variant === 'primary'
      ? Colors.P100
      : variant === 'secondary'
      ? Colors.B70
      : variant === 'disabled'
      ? Colors.B40
      : Colors.B50};
`;
